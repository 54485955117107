@font-face {
  font-family: 'Panton';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('#{$__FONTS_PATH}panton/Panton-Light.woff2');
}

@font-face {
  font-family: 'Panton';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('#{$__FONTS_PATH}panton/Panton-Regular.woff');
}

@font-face {
  font-family: 'Panton';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('#{$__FONTS_PATH}panton/Panton-SemiBold.woff2');
}

@font-face {
  font-family: 'Panton';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('#{$__FONTS_PATH}panton/Panton-Bold.woff2');
}

@font-face {
  font-family: 'Panton';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('#{$__FONTS_PATH}panton/Panton-ExtraBold.woff2');
}

@font-face {
  font-family: 'Panton';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('#{$__FONTS_PATH}panton/Panton-Black.woff2');
}