.marker {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: center/contain no-repeat #4996D1 url($__IMG_PATH + 'icons/ellipse.svg');
  box-shadow: var(--#{$prefix}box-shadow-inset);
}

.marked-list {
  &-with-arrow {
    li {
      position: relative;
      padding-left: 36px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background: center/contain no-repeat url($__IMG_PATH + 'icons/arrow-with-gradient-background.svg');
      }
    }
  }
}