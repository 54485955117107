$animation-duration: .5s;


.js-swap-animate-item {
  .swap-animation-item:first-child {
    transform: translateY(0);
    opacity: 1;
    animation: $animation-duration swap_visible;
  }

  .swap-animation-item:last-child {
    transform: translateY(100%);
    opacity: 0;
    animation: $animation-duration swap_invisible;
  }

  &.active {
    .swap-animation-item:first-child {
      transform: translateY(100%);
      opacity: 0;
      animation: $animation-duration swap_invisible;
    }

    .swap-animation-item:last-child {
      transform: translateY(0);
      opacity: 1;
      animation: $animation-duration swap_visible;
    }
  }
}


@keyframes swap_visible {
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes swap_invisible {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(-200%);
    opacity: 0;
  }
}