.text {
  &-inside {
    &-accent {
      &-strong {
        strong {
          color: var(--#{$prefix}accent);
        }
      }
    }

    &-secondary {
      &-strong {
        strong {
          color: var(--#{$prefix}secondary);
        }
      }
    }
  }

  &-gradient {
    color: var(--#{$prefix}primary);
    background: var(--#{$prefix}gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.small {
  @extend small;
}

.content-block {
  strong {
    font-weight: 700;
  }

  li + li {
    margin-top: 16px;
  }
}
