:root {
  // Colors
  //
  // Generate palettes for full colors, grays, and theme colors.
  @each $color, $value in $theme-colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  --#{$prefix}white-rgb: #{to-rgb($white)};
  --#{$prefix}black-rgb: #{to-rgb($black)};

  // Fonts

  // Note: Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --#{$prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
  --#{$prefix}font-monospace: #{inspect($font-family-monospace)};
  --#{$prefix}gradient: #{$gradient};

  // Root and body
  // scss-docs-start root-body-variables
  --#{$prefix}body-font-family: #{inspect($font-family-base)};
  --#{$prefix}body-color: #{$body-color};
  --#{$prefix}body-bg: #{$body-bg};
  --#{$prefix}body-bg-rgb: #{to-rgb($body-bg)};
  --#{$prefix}body-font-size: #{$font-size-base};
  --#{$prefix}body-font-weight: #{$font-weight-base};
  --#{$prefix}body-line-height: #{$line-height-base};

  --#{$prefix}border-radius: #{$border-radius};
  --#{$prefix}border-radius-sm: #{$border-radius-sm};
  --#{$prefix}border-radius-lg: #{$border-radius-lg};
  --#{$prefix}border-radius-xl: #{$border-radius-xl};
  --#{$prefix}border-radius-xxl: #{$border-radius-xxl};
  --#{$prefix}border-radius-3xl: #{$border-radius-3xl};
  --#{$prefix}border-radius-4xl: #{$border-radius-4xl};
  --#{$prefix}border-radius-pill: #{$border-radius-pill};

  --#{$prefix}box-shadow: #{$box-shadow};
  --#{$prefix}box-shadow-sm: #{$box-shadow-sm};
  --#{$prefix}box-shadow-lg: #{$box-shadow-lg};
  --#{$prefix}box-shadow-inset: #{$box-shadow-inset};

  @each $name, $value in $grid-breakpoints {
    --#{$prefix}breakpoint-#{$name}: #{$value};
  }
}