.btn {
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-family: #{$btn-font-family};
  --#{$prefix}btn-font-size: #{$btn-font-size};
  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$btn-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-hover-border-color: transparent;

  display: inline-block;
  padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
  font-family: var(--#{$prefix}btn-font-family);
  font-size: var(--#{$prefix}btn-font-size);
  font-weight: var(--#{$prefix}btn-font-weight);
  line-height: var(--#{$prefix}btn-line-height);
  color: var(--#{$prefix}btn-color);
  letter-spacing: 0.02em;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background: var(--#{$prefix}btn-bg);
  border: var(--#{$prefix}btn-border-width) solid var(--#{$prefix}btn-border-color);
  border-radius: var(--#{$prefix}btn-border-radius);
  transition: $btn-transition;

  &:hover {
    color: var(--#{$prefix}btn-hover-color);
    background: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
  }

  // Button Variants
  &-primary {
    --#{$prefix}btn-color: #{$grey};
    --#{$prefix}btn-bg: #{$gradient};
    --#{$prefix}btn-border-color: #{$pink};
    --#{$prefix}btn-hover-color: #{$grey};
    --#{$prefix}btn-hover-bg: #{$pink};
    --#{$prefix}btn-hover-border-color: #{$pink};
    --#{$prefix}btn-active-color: #{$grey};
    --#{$prefix}btn-active-bg: #{$pink};
    --#{$prefix}btn-active-border-color: #{$pink};
    --#{$prefix}btn-disabled-color: #{$white};
    --#{$prefix}btn-disabled-bg: #{$body-color};
    --#{$prefix}btn-disabled-border-color: #{$body-color};
  }

  &-accent {
    --#{$prefix}btn-color: #{$secondary};
    --#{$prefix}btn-bg: #{$accent};
    --#{$prefix}btn-border-color: #{$accent};
    --#{$prefix}btn-hover-color: #{$accent};
    --#{$prefix}btn-hover-bg: #{$secondary};
    --#{$prefix}btn-hover-border-color: #{$secondary};
    --#{$prefix}btn-active-color: #{$accent};
    --#{$prefix}btn-active-bg: #{$secondary};
    --#{$prefix}btn-active-border-color: #{$secondary};
    --#{$prefix}btn-disabled-color: #{$white};
    --#{$prefix}btn-disabled-bg: #{$body-color};
    --#{$prefix}btn-disabled-border-color: #{$body-color};
  }

  &-hover-icon {
    & > .bi {
      transition: margin .15s ease-in-out;
    }

    &:hover {
      & > .bi {
        margin-left: 12px;
      }
    }
  }

  // Button Sizes
  &-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
  }

  &-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
  }
}
