.bg {
  &-code {
    &-border {
      background: #{$gradient-code-border};
    }

    &-mask {
      &:before {
        content: '';
        border-radius: inherit;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #{$gradient-code-mask};
      }
    }
  }

  &-hiw {
    &-border {
      background: #{$gradient-gray-border};
    }
  }

  &-homepage {
    &-main {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -1px;
        z-index: 1;
        background: linear-gradient(360deg, #FFFFFF 1.19%, rgba(229, 224, 248, 0.835) 22.56%, rgba(99, 70, 214, 0) 55.3%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
}

.fintech_fast_track {
  background-repeat: no-repeat;
  background-position: center top 0;
  background-size: cover;
  background-image: url('#{$__IMG_PATH}new/fintech-fast-track/background-mobile.jpg');
  @include media-breakpoint-up(sm) {
    background-image: url('#{$__IMG_PATH}new/fintech-fast-track/background-desktop.jpg');
  }
}

.new_homepage {
  background-repeat: no-repeat;
  background-position: center top 0;
  background-size: cover;
  background-image: url('#{$__IMG_PATH}new/homepage/background-desktop.jpg');
}