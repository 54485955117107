.bi {
  --#{$prefix}icon-size: 1em;

  fill: currentColor;
  flex-shrink: 0;
  width: var(--#{$prefix}icon-size);
  height: var(--#{$prefix}icon-size);

  &-20 {
    --#{$prefix}icon-size: 20px;
  }
}