:root {
  --d-primary: #559BEB;
  --d-secondary: #8592F4;
  --d-accent: #94F7FF;
  --d-pink: #CE72CB;
  --d-primary-rgb: 85, 155, 235;
  --d-secondary-rgb: 133, 146, 244;
  --d-accent-rgb: 148, 247, 255;
  --d-pink-rgb: 206, 114, 203;
  --d-white-rgb: 255, 255, 255;
  --d-black-rgb: 0, 0, 0;
  --d-font-sans-serif: "Panton", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --d-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --d-gradient: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%) #559BEB;
  --d-body-font-family: var(--d-font-sans-serif);
  --d-body-color: #5C6876;
  --d-body-bg: #FFF;
  --d-body-bg-rgb: 255, 255, 255;
  --d-body-font-size: 16px;
  --d-body-font-weight: 400;
  --d-body-line-height: 1.25;
  --d-border-radius: 16px;
  --d-border-radius-sm: 14px;
  --d-border-radius-lg: 18px;
  --d-border-radius-xl: 20px;
  --d-border-radius-xxl: 24px;
  --d-border-radius-3xl: 40px;
  --d-border-radius-4xl: 68px;
  --d-border-radius-pill: 50rem;
  --d-box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -15px 2px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;
  --d-box-shadow-sm: 0 4px 30px 0 rgba(43, 61, 74, 0.36);
  --d-box-shadow-lg: -8px -10px 30px 0 rgba(232, 234, 243, 0.05), 18px 12px 45px 0 rgba(23, 37, 87, 0.42), -16px -6px 80px 0 rgba(248, 249, 249, 0.03) inset;
  --d-box-shadow-inset: 2px 1px 3px 0 rgba(24, 47, 91, 0.2), -2px -1px 4px 0 rgba(255, 255, 255, 0.4), -4px -3px 4px 0 rgba(79, 103, 120, 0.2) inset, 3px 3px 5px 0 rgba(213, 222, 245, 0.4) inset, 0 0 4px 0 rgba(49, 46, 87, 0) inset;
  --d-breakpoint-xs: 0;
  --d-breakpoint-sm: 576px;
  --d-breakpoint-md: 768px;
  --d-breakpoint-lg: 992px;
  --d-breakpoint-xl: 1200px;
  --d-breakpoint-xxl: 1320px;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small,
.small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: var(--d-body-font-family);
  font-size: var(--d-body-font-size);
  font-weight: var(--d-body-font-weight);
  line-height: var(--d-body-line-height);
  color: var(--d-body-color);
  text-align: var(--d-body-text-align);
  background-color: var(--d-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  position: relative;
}
@media (max-width: 1024px) {
  main {
    padding-top: 68px;
  }
}

small, .small {
  font-size: 14px;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #559BEB;
  -webkit-background-clip: text;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: "Panton";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("/assets/website/fonts/panton/Panton-Light.woff2");
}
@font-face {
  font-family: "Panton";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("/assets/website/fonts/panton/Panton-Regular.woff");
}
@font-face {
  font-family: "Panton";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("/assets/website/fonts/panton/Panton-SemiBold.woff2");
}
@font-face {
  font-family: "Panton";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("/assets/website/fonts/panton/Panton-Bold.woff2");
}
@font-face {
  font-family: "Panton";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("/assets/website/fonts/panton/Panton-ExtraBold.woff2");
}
@font-face {
  font-family: "Panton";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("/assets/website/fonts/panton/Panton-Black.woff2");
}
.text-inside-accent-strong strong {
  color: var(--d-accent);
}
.text-inside-secondary-strong strong {
  color: var(--d-secondary);
}
.text-gradient {
  color: var(--d-primary);
  background: var(--d-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content-block strong {
  font-weight: 700;
}
.content-block li + li {
  margin-top: 16px;
}

.row {
  --d-gutter-x: 1.5rem;
  --d-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--d-gutter-y));
  margin-right: calc(-0.5 * var(--d-gutter-x));
  margin-left: calc(-0.5 * var(--d-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--d-gutter-x) * 0.5);
  padding-left: calc(var(--d-gutter-x) * 0.5);
  margin-top: var(--d-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.g-0,
.gx-0 {
  --d-gutter-x: 0;
}

.g-0,
.gy-0 {
  --d-gutter-y: 0;
}

.g-1,
.gx-1 {
  --d-gutter-x: 4px;
}

.g-1,
.gy-1 {
  --d-gutter-y: 4px;
}

.g-2,
.gx-2 {
  --d-gutter-x: 8px;
}

.g-2,
.gy-2 {
  --d-gutter-y: 8px;
}

.g-3,
.gx-3 {
  --d-gutter-x: 16px;
}

.g-3,
.gy-3 {
  --d-gutter-y: 16px;
}

.g-4,
.gx-4 {
  --d-gutter-x: 24px;
}

.g-4,
.gy-4 {
  --d-gutter-y: 24px;
}

.g-5,
.gx-5 {
  --d-gutter-x: 32px;
}

.g-5,
.gy-5 {
  --d-gutter-y: 32px;
}

.g-6,
.gx-6 {
  --d-gutter-x: 48px;
}

.g-6,
.gy-6 {
  --d-gutter-y: 48px;
}

.g-7,
.gx-7 {
  --d-gutter-x: 74px;
}

.g-7,
.gy-7 {
  --d-gutter-y: 74px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .g-sm-0,
.gx-sm-0 {
    --d-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --d-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --d-gutter-x: 4px;
  }

  .g-sm-1,
.gy-sm-1 {
    --d-gutter-y: 4px;
  }

  .g-sm-2,
.gx-sm-2 {
    --d-gutter-x: 8px;
  }

  .g-sm-2,
.gy-sm-2 {
    --d-gutter-y: 8px;
  }

  .g-sm-3,
.gx-sm-3 {
    --d-gutter-x: 16px;
  }

  .g-sm-3,
.gy-sm-3 {
    --d-gutter-y: 16px;
  }

  .g-sm-4,
.gx-sm-4 {
    --d-gutter-x: 24px;
  }

  .g-sm-4,
.gy-sm-4 {
    --d-gutter-y: 24px;
  }

  .g-sm-5,
.gx-sm-5 {
    --d-gutter-x: 32px;
  }

  .g-sm-5,
.gy-sm-5 {
    --d-gutter-y: 32px;
  }

  .g-sm-6,
.gx-sm-6 {
    --d-gutter-x: 48px;
  }

  .g-sm-6,
.gy-sm-6 {
    --d-gutter-y: 48px;
  }

  .g-sm-7,
.gx-sm-7 {
    --d-gutter-x: 74px;
  }

  .g-sm-7,
.gy-sm-7 {
    --d-gutter-y: 74px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .g-md-0,
.gx-md-0 {
    --d-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --d-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --d-gutter-x: 4px;
  }

  .g-md-1,
.gy-md-1 {
    --d-gutter-y: 4px;
  }

  .g-md-2,
.gx-md-2 {
    --d-gutter-x: 8px;
  }

  .g-md-2,
.gy-md-2 {
    --d-gutter-y: 8px;
  }

  .g-md-3,
.gx-md-3 {
    --d-gutter-x: 16px;
  }

  .g-md-3,
.gy-md-3 {
    --d-gutter-y: 16px;
  }

  .g-md-4,
.gx-md-4 {
    --d-gutter-x: 24px;
  }

  .g-md-4,
.gy-md-4 {
    --d-gutter-y: 24px;
  }

  .g-md-5,
.gx-md-5 {
    --d-gutter-x: 32px;
  }

  .g-md-5,
.gy-md-5 {
    --d-gutter-y: 32px;
  }

  .g-md-6,
.gx-md-6 {
    --d-gutter-x: 48px;
  }

  .g-md-6,
.gy-md-6 {
    --d-gutter-y: 48px;
  }

  .g-md-7,
.gx-md-7 {
    --d-gutter-x: 74px;
  }

  .g-md-7,
.gy-md-7 {
    --d-gutter-y: 74px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .g-lg-0,
.gx-lg-0 {
    --d-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --d-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --d-gutter-x: 4px;
  }

  .g-lg-1,
.gy-lg-1 {
    --d-gutter-y: 4px;
  }

  .g-lg-2,
.gx-lg-2 {
    --d-gutter-x: 8px;
  }

  .g-lg-2,
.gy-lg-2 {
    --d-gutter-y: 8px;
  }

  .g-lg-3,
.gx-lg-3 {
    --d-gutter-x: 16px;
  }

  .g-lg-3,
.gy-lg-3 {
    --d-gutter-y: 16px;
  }

  .g-lg-4,
.gx-lg-4 {
    --d-gutter-x: 24px;
  }

  .g-lg-4,
.gy-lg-4 {
    --d-gutter-y: 24px;
  }

  .g-lg-5,
.gx-lg-5 {
    --d-gutter-x: 32px;
  }

  .g-lg-5,
.gy-lg-5 {
    --d-gutter-y: 32px;
  }

  .g-lg-6,
.gx-lg-6 {
    --d-gutter-x: 48px;
  }

  .g-lg-6,
.gy-lg-6 {
    --d-gutter-y: 48px;
  }

  .g-lg-7,
.gx-lg-7 {
    --d-gutter-x: 74px;
  }

  .g-lg-7,
.gy-lg-7 {
    --d-gutter-y: 74px;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .g-xl-0,
.gx-xl-0 {
    --d-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --d-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --d-gutter-x: 4px;
  }

  .g-xl-1,
.gy-xl-1 {
    --d-gutter-y: 4px;
  }

  .g-xl-2,
.gx-xl-2 {
    --d-gutter-x: 8px;
  }

  .g-xl-2,
.gy-xl-2 {
    --d-gutter-y: 8px;
  }

  .g-xl-3,
.gx-xl-3 {
    --d-gutter-x: 16px;
  }

  .g-xl-3,
.gy-xl-3 {
    --d-gutter-y: 16px;
  }

  .g-xl-4,
.gx-xl-4 {
    --d-gutter-x: 24px;
  }

  .g-xl-4,
.gy-xl-4 {
    --d-gutter-y: 24px;
  }

  .g-xl-5,
.gx-xl-5 {
    --d-gutter-x: 32px;
  }

  .g-xl-5,
.gy-xl-5 {
    --d-gutter-y: 32px;
  }

  .g-xl-6,
.gx-xl-6 {
    --d-gutter-x: 48px;
  }

  .g-xl-6,
.gy-xl-6 {
    --d-gutter-y: 48px;
  }

  .g-xl-7,
.gx-xl-7 {
    --d-gutter-x: 74px;
  }

  .g-xl-7,
.gy-xl-7 {
    --d-gutter-y: 74px;
  }
}
@media (min-width: 1320px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --d-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --d-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --d-gutter-x: 4px;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --d-gutter-y: 4px;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --d-gutter-x: 8px;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --d-gutter-y: 8px;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --d-gutter-x: 16px;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --d-gutter-y: 16px;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --d-gutter-x: 24px;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --d-gutter-y: 24px;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --d-gutter-x: 32px;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --d-gutter-y: 32px;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --d-gutter-x: 48px;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --d-gutter-y: 48px;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --d-gutter-x: 74px;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --d-gutter-y: 74px;
  }
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --d-gutter-x: 32px;
  --d-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--d-gutter-x) * 0.5);
  padding-left: calc(var(--d-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1320px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1302px;
  }
}
.btn {
  --d-btn-padding-x: 20px;
  --d-btn-padding-y: 8px;
  --d-btn-font-family: Panton, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --d-btn-font-size: 14px;
  --d-btn-font-weight: 600;
  --d-btn-line-height: 1.429;
  --d-btn-color: var(--d-body-color);
  --d-btn-bg: transparent;
  --d-btn-border-width: 0;
  --d-btn-border-color: transparent;
  --d-btn-border-radius: var(--d-border-radius-pill);
  --d-btn-hover-border-color: transparent;
  display: inline-block;
  padding: var(--d-btn-padding-y) var(--d-btn-padding-x);
  font-family: var(--d-btn-font-family);
  font-size: var(--d-btn-font-size);
  font-weight: var(--d-btn-font-weight);
  line-height: var(--d-btn-line-height);
  color: var(--d-btn-color);
  letter-spacing: 0.02em;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background: var(--d-btn-bg);
  border: var(--d-btn-border-width) solid var(--d-btn-border-color);
  border-radius: var(--d-btn-border-radius);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover {
  color: var(--d-btn-hover-color);
  background: var(--d-btn-hover-bg);
  border-color: var(--d-btn-hover-border-color);
}
.btn-primary {
  --d-btn-color: #F3F5F7;
  --d-btn-bg: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%) #559BEB;
  --d-btn-border-color: #CE72CB;
  --d-btn-hover-color: #F3F5F7;
  --d-btn-hover-bg: #CE72CB;
  --d-btn-hover-border-color: #CE72CB;
  --d-btn-active-color: #F3F5F7;
  --d-btn-active-bg: #CE72CB;
  --d-btn-active-border-color: #CE72CB;
  --d-btn-disabled-color: #FFF;
  --d-btn-disabled-bg: #5C6876;
  --d-btn-disabled-border-color: #5C6876;
}
.btn-accent {
  --d-btn-color: #8592F4;
  --d-btn-bg: #94F7FF;
  --d-btn-border-color: #94F7FF;
  --d-btn-hover-color: #94F7FF;
  --d-btn-hover-bg: #8592F4;
  --d-btn-hover-border-color: #8592F4;
  --d-btn-active-color: #94F7FF;
  --d-btn-active-bg: #8592F4;
  --d-btn-active-border-color: #8592F4;
  --d-btn-disabled-color: #FFF;
  --d-btn-disabled-bg: #5C6876;
  --d-btn-disabled-border-color: #5C6876;
}
.btn-hover-icon > .bi {
  transition: margin 0.15s ease-in-out;
}
.btn-hover-icon:hover > .bi {
  margin-left: 12px;
}
.btn-lg {
  --d-btn-padding-y: 8px;
  --d-btn-padding-x: 20px;
  --d-btn-font-size: 18px;
  --d-btn-line-height: 1.445;
  --d-btn-border-radius: var(--d-border-radius-pill);
}
.btn-sm {
  --d-btn-padding-y: 8px;
  --d-btn-padding-x: 20px;
  --d-btn-font-size: 14px;
  --d-btn-line-height: 1.429;
  --d-btn-border-radius: var(--d-border-radius-pill);
}

.bi {
  --d-icon-size: 1em;
  fill: currentColor;
  flex-shrink: 0;
  width: var(--d-icon-size);
  height: var(--d-icon-size);
}
.bi-20 {
  --d-icon-size: 20px;
}

.bg-code-border {
  background: linear-gradient(180deg, #A0FAFC 0.13%, rgba(160, 250, 252, 0) 90.54%), linear-gradient(90deg, #5E6FB1 0%, #584B7B 100%);
}
.bg-code-mask:before {
  content: "";
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(318deg, rgba(0, 0, 0, 0.5) 20.61%, rgba(0, 0, 0, 0) 88.71%);
}
.bg-hiw-border {
  background: linear-gradient(118.53deg, #FFFFFF 26%, rgba(219, 230, 242, 0) 51%, #8F9FAE 100%);
}
.bg-homepage-main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  z-index: 1;
  background: linear-gradient(360deg, #FFFFFF 1.19%, rgba(229, 224, 248, 0.835) 22.56%, rgba(99, 70, 214, 0) 55.3%, rgba(255, 255, 255, 0) 100%);
}

.fintech_fast_track {
  background-repeat: no-repeat;
  background-position: center top 0;
  background-size: cover;
  background-image: url("/assets/website/img/new/fintech-fast-track/background-mobile.jpg");
}
@media (min-width: 576px) {
  .fintech_fast_track {
    background-image: url("/assets/website/img/new/fintech-fast-track/background-desktop.jpg");
  }
}

.new_homepage {
  background-repeat: no-repeat;
  background-position: center top 0;
  background-size: cover;
  background-image: url("/assets/website/img/new/homepage/background-desktop.jpg");
}

.marker {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: center/contain no-repeat #4996D1 url("/assets/website/img/icons/ellipse.svg");
  box-shadow: var(--d-box-shadow-inset);
}

.marked-list-with-arrow li {
  position: relative;
  padding-left: 36px;
}
.marked-list-with-arrow li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background: center/contain no-repeat url("/assets/website/img/icons/arrow-with-gradient-background.svg");
}

.ratio-post {
  --d-aspect-ratio: 78.5714285714%;
}
@media (min-width: 768px) {
  .ratio-post {
    --d-aspect-ratio: 72.7272727273%;
  }
}

.js-swap-animate-item .swap-animation-item:first-child {
  transform: translateY(0);
  opacity: 1;
  animation: 0.5s swap_visible;
}
.js-swap-animate-item .swap-animation-item:last-child {
  transform: translateY(100%);
  opacity: 0;
  animation: 0.5s swap_invisible;
}
.js-swap-animate-item.active .swap-animation-item:first-child {
  transform: translateY(100%);
  opacity: 0;
  animation: 0.5s swap_invisible;
}
.js-swap-animate-item.active .swap-animation-item:last-child {
  transform: translateY(0);
  opacity: 1;
  animation: 0.5s swap_visible;
}

@keyframes swap_visible {
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes swap_invisible {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(-200%);
    opacity: 0;
  }
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--d-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.object-fit-cover {
  object-fit: cover;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: var(--d-box-shadow);
}

.shadow-sm {
  box-shadow: var(--d-box-shadow-sm);
}

.shadow-lg {
  box-shadow: var(--d-box-shadow-lg);
}

.shadow-none {
  box-shadow: none;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.w-72px {
  width: 72px;
}

.w-140px {
  width: 140px;
}

.w-180px {
  width: 180px;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-240px {
  max-width: 240px;
}

.mw-250px {
  max-width: 250px;
}

.mw-280px {
  max-width: 280px;
}

.mw-300px {
  max-width: 300px;
}

.mw-400px {
  max-width: 400px;
}

.mw-450px {
  max-width: 450px;
}

.mw-650px {
  max-width: 650px;
}

.mw-70 {
  max-width: 70%;
}

.mw-100 {
  max-width: 100%;
}

.h-60px {
  height: 60px;
}

.h-72px {
  height: 72px;
}

.h-80px {
  height: 80px;
}

.h-140px {
  height: 140px;
}

.h-180px {
  height: 180px;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-1em {
  max-height: 1em;
}

.mh-170px {
  max-height: 170px;
}

.mh-100 {
  max-height: 100%;
}

.min-h-155px {
  min-height: 155px;
}

.min-h-400px {
  min-height: 400px;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-equal {
  flex: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 4px;
}

.m-2 {
  margin: 8px;
}

.m-3 {
  margin: 16px;
}

.m-4 {
  margin: 24px;
}

.m-5 {
  margin: 32px;
}

.m-6 {
  margin: 48px;
}

.m-7 {
  margin: 74px;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: 4px;
  margin-left: 4px;
}

.mx-2 {
  margin-right: 8px;
  margin-left: 8px;
}

.mx-3 {
  margin-right: 16px;
  margin-left: 16px;
}

.mx-4 {
  margin-right: 24px;
  margin-left: 24px;
}

.mx-5 {
  margin-right: 32px;
  margin-left: 32px;
}

.mx-6 {
  margin-right: 48px;
  margin-left: 48px;
}

.mx-7 {
  margin-right: 74px;
  margin-left: 74px;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-3 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-4 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-5 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-6 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.my-7 {
  margin-top: 74px;
  margin-bottom: 74px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 16px;
}

.mt-4 {
  margin-top: 24px;
}

.mt-5 {
  margin-top: 32px;
}

.mt-6 {
  margin-top: 48px;
}

.mt-7 {
  margin-top: 74px;
}

.mt-auto {
  margin-top: auto;
}

.mt-40px {
  margin-top: 40px;
}

.mt-60px {
  margin-top: 60px;
}

.mt-80px {
  margin-top: 80px;
}

.mt-n7 {
  margin-top: -74px;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: 4px;
}

.me-2 {
  margin-right: 8px;
}

.me-3 {
  margin-right: 16px;
}

.me-4 {
  margin-right: 24px;
}

.me-5 {
  margin-right: 32px;
}

.me-6 {
  margin-right: 48px;
}

.me-7 {
  margin-right: 74px;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 16px;
}

.mb-4 {
  margin-bottom: 24px;
}

.mb-5 {
  margin-bottom: 32px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mb-7 {
  margin-bottom: 74px;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-40px {
  margin-bottom: 40px;
}

.mb-80px {
  margin-bottom: 80px;
}

.mb-n4 {
  margin-bottom: -24px;
}

.mb-n6 {
  margin-bottom: -48px;
}

.mb-n7 {
  margin-bottom: -74px;
}

.mb-n60px {
  margin-bottom: -60px;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: 4px;
}

.ms-2 {
  margin-left: 8px;
}

.ms-3 {
  margin-left: 16px;
}

.ms-4 {
  margin-left: 24px;
}

.ms-5 {
  margin-left: 32px;
}

.ms-6 {
  margin-left: 48px;
}

.ms-7 {
  margin-left: 74px;
}

.ms-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 4px;
}

.p-2 {
  padding: 8px;
}

.p-3 {
  padding: 16px;
}

.p-4 {
  padding: 24px;
}

.p-5 {
  padding: 32px;
}

.p-6 {
  padding: 48px;
}

.p-7 {
  padding: 74px;
}

.p-1px {
  padding: 1px;
}

.p-2px {
  padding: 2px;
}

.p-20px {
  padding: 20px;
}

.p-40px {
  padding: 40px;
}

.p-60px {
  padding: 60px;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1 {
  padding-right: 4px;
  padding-left: 4px;
}

.px-2 {
  padding-right: 8px;
  padding-left: 8px;
}

.px-3 {
  padding-right: 16px;
  padding-left: 16px;
}

.px-4 {
  padding-right: 24px;
  padding-left: 24px;
}

.px-5 {
  padding-right: 32px;
  padding-left: 32px;
}

.px-6 {
  padding-right: 48px;
  padding-left: 48px;
}

.px-7 {
  padding-right: 74px;
  padding-left: 74px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-3 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-4 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-5 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.py-7 {
  padding-top: 74px;
  padding-bottom: 74px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 4px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 16px;
}

.pt-4 {
  padding-top: 24px;
}

.pt-5 {
  padding-top: 32px;
}

.pt-6 {
  padding-top: 48px;
}

.pt-7 {
  padding-top: 74px;
}

.pe-0 {
  padding-right: 0;
}

.pe-1 {
  padding-right: 4px;
}

.pe-2 {
  padding-right: 8px;
}

.pe-3 {
  padding-right: 16px;
}

.pe-4 {
  padding-right: 24px;
}

.pe-5 {
  padding-right: 32px;
}

.pe-6 {
  padding-right: 48px;
}

.pe-7 {
  padding-right: 74px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 4px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-3 {
  padding-bottom: 16px;
}

.pb-4 {
  padding-bottom: 24px;
}

.pb-5 {
  padding-bottom: 32px;
}

.pb-6 {
  padding-bottom: 48px;
}

.pb-7 {
  padding-bottom: 74px;
}

.ps-0 {
  padding-left: 0;
}

.ps-1 {
  padding-left: 4px;
}

.ps-2 {
  padding-left: 8px;
}

.ps-3 {
  padding-left: 16px;
}

.ps-4 {
  padding-left: 24px;
}

.ps-5 {
  padding-left: 32px;
}

.ps-6 {
  padding-left: 48px;
}

.ps-7 {
  padding-left: 74px;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 4px;
}

.gap-2 {
  gap: 8px;
}

.gap-3 {
  gap: 16px;
}

.gap-4 {
  gap: 24px;
}

.gap-5 {
  gap: 32px;
}

.gap-6 {
  gap: 48px;
}

.gap-7 {
  gap: 74px;
}

.row-gap-0 {
  row-gap: 0;
}

.row-gap-1 {
  row-gap: 4px;
}

.row-gap-2 {
  row-gap: 8px;
}

.row-gap-3 {
  row-gap: 16px;
}

.row-gap-4 {
  row-gap: 24px;
}

.row-gap-5 {
  row-gap: 32px;
}

.row-gap-6 {
  row-gap: 48px;
}

.row-gap-7 {
  row-gap: 74px;
}

.column-gap-0 {
  column-gap: 0;
}

.column-gap-1 {
  column-gap: 4px;
}

.column-gap-2 {
  column-gap: 8px;
}

.column-gap-3 {
  column-gap: 16px;
}

.column-gap-4 {
  column-gap: 24px;
}

.column-gap-5 {
  column-gap: 32px;
}

.column-gap-6 {
  column-gap: 48px;
}

.column-gap-7 {
  column-gap: 74px;
}

.font-monospace {
  font-family: var(--d-font-monospace);
}

.fs-1 {
  font-size: 64px;
}

.fs-2 {
  font-size: 48px;
}

.fs-3 {
  font-size: 42px;
}

.fs-4 {
  font-size: 36px;
}

.fs-5 {
  font-size: 24px;
}

.fs-6 {
  font-size: 18px;
}

.fs-7 {
  font-size: 14px;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extra-bold {
  font-weight: 800;
}

.fw-bolder {
  font-weight: 900;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.3333;
}

.lh-base {
  line-height: 1.25;
}

.lh-lg {
  line-height: 1.4;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-spacing-2 {
  letter-spacing: 0.02em;
}

.text-spacing-3 {
  letter-spacing: 0.03em;
}

.text-primary {
  --d-text-opacity: 1;
  color: rgba(var(--d-primary-rgb), var(--d-text-opacity));
}

.text-secondary {
  --d-text-opacity: 1;
  color: rgba(var(--d-secondary-rgb), var(--d-text-opacity));
}

.text-accent {
  --d-text-opacity: 1;
  color: rgba(var(--d-accent-rgb), var(--d-text-opacity));
}

.text-pink {
  --d-text-opacity: 1;
  color: rgba(var(--d-pink-rgb), var(--d-text-opacity));
}

.text-black {
  --d-text-opacity: 1;
  color: rgba(var(--d-black-rgb), var(--d-text-opacity));
}

.text-white {
  --d-text-opacity: 1;
  color: rgba(var(--d-white-rgb), var(--d-text-opacity));
}

.text-body {
  --d-text-opacity: 1;
  color: rgba(var(--d-body-color-rgb), var(--d-text-opacity));
}

.text-muted {
  --d-text-opacity: 1;
  color: var(--d-secondary-color);
}

.text-black-50 {
  --d-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --d-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-body-secondary {
  --d-text-opacity: 1;
  color: var(--d-secondary-color);
}

.text-body-tertiary {
  --d-text-opacity: 1;
  color: var(--d-tertiary-color);
}

.text-body-emphasis {
  --d-text-opacity: 1;
  color: var(--d-emphasis-color);
}

.text-reset {
  --d-text-opacity: 1;
  color: inherit;
}

.bg-primary {
  --d-bg-opacity: 1;
  background-color: rgba(var(--d-primary-rgb), var(--d-bg-opacity));
}

.bg-secondary {
  --d-bg-opacity: 1;
  background-color: rgba(var(--d-secondary-rgb), var(--d-bg-opacity));
}

.bg-accent {
  --d-bg-opacity: 1;
  background-color: rgba(var(--d-accent-rgb), var(--d-bg-opacity));
}

.bg-pink {
  --d-bg-opacity: 1;
  background-color: rgba(var(--d-pink-rgb), var(--d-bg-opacity));
}

.bg-black {
  --d-bg-opacity: 1;
  background-color: rgba(var(--d-black-rgb), var(--d-bg-opacity));
}

.bg-white {
  --d-bg-opacity: 1;
  background-color: rgba(var(--d-white-rgb), var(--d-bg-opacity));
}

.bg-body {
  --d-bg-opacity: 1;
  background-color: rgba(var(--d-body-bg-rgb), var(--d-bg-opacity));
}

.bg-transparent {
  --d-bg-opacity: 1;
  background-color: transparent;
}

.bg-body-secondary {
  --d-bg-opacity: 1;
  background-color: rgba(var(--d-secondary-bg-rgb), var(--d-bg-opacity));
}

.bg-body-tertiary {
  --d-bg-opacity: 1;
  background-color: rgba(var(--d-tertiary-bg-rgb), var(--d-bg-opacity));
}

.bg-gradient {
  background: var(--d-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: var(--d-border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: var(--d-border-radius-sm);
}

.rounded-2 {
  border-radius: var(--d-border-radius);
}

.rounded-3 {
  border-radius: var(--d-border-radius-lg);
}

.rounded-4 {
  border-radius: var(--d-border-radius-xl);
}

.rounded-5 {
  border-radius: var(--d-border-radius-xxl);
}

.rounded-6 {
  border-radius: var(--d-border-radius-3xl);
}

.rounded-7 {
  border-radius: var(--d-border-radius-4xl);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: var(--d-border-radius-pill);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.z-n1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline;
  }

  .d-sm-inline-block {
    display: inline-block;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-grid {
    display: grid;
  }

  .d-sm-inline-grid {
    display: inline-grid;
  }

  .d-sm-table {
    display: table;
  }

  .d-sm-table-row {
    display: table-row;
  }

  .d-sm-table-cell {
    display: table-cell;
  }

  .d-sm-flex {
    display: flex;
  }

  .d-sm-inline-flex {
    display: inline-flex;
  }

  .d-sm-none {
    display: none;
  }

  .position-sm-static {
    position: static;
  }

  .position-sm-relative {
    position: relative;
  }

  .position-sm-absolute {
    position: absolute;
  }

  .position-sm-fixed {
    position: fixed;
  }

  .position-sm-sticky {
    position: sticky;
  }

  .w-sm-72px {
    width: 72px;
  }

  .w-sm-140px {
    width: 140px;
  }

  .w-sm-180px {
    width: 180px;
  }

  .w-sm-25 {
    width: 25%;
  }

  .w-sm-50 {
    width: 50%;
  }

  .w-sm-75 {
    width: 75%;
  }

  .w-sm-100 {
    width: 100%;
  }

  .w-sm-auto {
    width: auto;
  }

  .mw-sm-240px {
    max-width: 240px;
  }

  .mw-sm-250px {
    max-width: 250px;
  }

  .mw-sm-280px {
    max-width: 280px;
  }

  .mw-sm-300px {
    max-width: 300px;
  }

  .mw-sm-400px {
    max-width: 400px;
  }

  .mw-sm-450px {
    max-width: 450px;
  }

  .mw-sm-650px {
    max-width: 650px;
  }

  .mw-sm-70 {
    max-width: 70%;
  }

  .mw-sm-100 {
    max-width: 100%;
  }

  .h-sm-60px {
    height: 60px;
  }

  .h-sm-72px {
    height: 72px;
  }

  .h-sm-80px {
    height: 80px;
  }

  .h-sm-140px {
    height: 140px;
  }

  .h-sm-180px {
    height: 180px;
  }

  .h-sm-25 {
    height: 25%;
  }

  .h-sm-50 {
    height: 50%;
  }

  .h-sm-75 {
    height: 75%;
  }

  .h-sm-100 {
    height: 100%;
  }

  .h-sm-auto {
    height: auto;
  }

  .flex-sm-fill {
    flex: 1 1 auto;
  }

  .flex-sm-equal {
    flex: 1;
  }

  .flex-sm-row {
    flex-direction: row;
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-sm-grow-0 {
    flex-grow: 0;
  }

  .flex-sm-grow-1 {
    flex-grow: 1;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }

  .flex-sm-wrap {
    flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-sm-start {
    justify-content: flex-start;
  }

  .justify-content-sm-end {
    justify-content: flex-end;
  }

  .justify-content-sm-center {
    justify-content: center;
  }

  .justify-content-sm-between {
    justify-content: space-between;
  }

  .justify-content-sm-around {
    justify-content: space-around;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }

  .align-items-sm-start {
    align-items: flex-start;
  }

  .align-items-sm-end {
    align-items: flex-end;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .align-items-sm-baseline {
    align-items: baseline;
  }

  .align-items-sm-stretch {
    align-items: stretch;
  }

  .align-self-sm-auto {
    align-self: auto;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .align-self-sm-end {
    align-self: flex-end;
  }

  .align-self-sm-center {
    align-self: center;
  }

  .align-self-sm-baseline {
    align-self: baseline;
  }

  .align-self-sm-stretch {
    align-self: stretch;
  }

  .m-sm-0 {
    margin: 0;
  }

  .m-sm-1 {
    margin: 4px;
  }

  .m-sm-2 {
    margin: 8px;
  }

  .m-sm-3 {
    margin: 16px;
  }

  .m-sm-4 {
    margin: 24px;
  }

  .m-sm-5 {
    margin: 32px;
  }

  .m-sm-6 {
    margin: 48px;
  }

  .m-sm-7 {
    margin: 74px;
  }

  .m-sm-auto {
    margin: auto;
  }

  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-sm-1 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .mx-sm-2 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .mx-sm-3 {
    margin-right: 16px;
    margin-left: 16px;
  }

  .mx-sm-4 {
    margin-right: 24px;
    margin-left: 24px;
  }

  .mx-sm-5 {
    margin-right: 32px;
    margin-left: 32px;
  }

  .mx-sm-6 {
    margin-right: 48px;
    margin-left: 48px;
  }

  .mx-sm-7 {
    margin-right: 74px;
    margin-left: 74px;
  }

  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-sm-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .my-sm-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .my-sm-3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .my-sm-4 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .my-sm-5 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .my-sm-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .my-sm-7 {
    margin-top: 74px;
    margin-bottom: 74px;
  }

  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-sm-0 {
    margin-top: 0;
  }

  .mt-sm-1 {
    margin-top: 4px;
  }

  .mt-sm-2 {
    margin-top: 8px;
  }

  .mt-sm-3 {
    margin-top: 16px;
  }

  .mt-sm-4 {
    margin-top: 24px;
  }

  .mt-sm-5 {
    margin-top: 32px;
  }

  .mt-sm-6 {
    margin-top: 48px;
  }

  .mt-sm-7 {
    margin-top: 74px;
  }

  .mt-sm-auto {
    margin-top: auto;
  }

  .mt-sm-40px {
    margin-top: 40px;
  }

  .mt-sm-60px {
    margin-top: 60px;
  }

  .mt-sm-80px {
    margin-top: 80px;
  }

  .mt-sm-n7 {
    margin-top: -74px;
  }

  .me-sm-0 {
    margin-right: 0;
  }

  .me-sm-1 {
    margin-right: 4px;
  }

  .me-sm-2 {
    margin-right: 8px;
  }

  .me-sm-3 {
    margin-right: 16px;
  }

  .me-sm-4 {
    margin-right: 24px;
  }

  .me-sm-5 {
    margin-right: 32px;
  }

  .me-sm-6 {
    margin-right: 48px;
  }

  .me-sm-7 {
    margin-right: 74px;
  }

  .me-sm-auto {
    margin-right: auto;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  .mb-sm-1 {
    margin-bottom: 4px;
  }

  .mb-sm-2 {
    margin-bottom: 8px;
  }

  .mb-sm-3 {
    margin-bottom: 16px;
  }

  .mb-sm-4 {
    margin-bottom: 24px;
  }

  .mb-sm-5 {
    margin-bottom: 32px;
  }

  .mb-sm-6 {
    margin-bottom: 48px;
  }

  .mb-sm-7 {
    margin-bottom: 74px;
  }

  .mb-sm-auto {
    margin-bottom: auto;
  }

  .mb-sm-40px {
    margin-bottom: 40px;
  }

  .mb-sm-80px {
    margin-bottom: 80px;
  }

  .mb-sm-n4 {
    margin-bottom: -24px;
  }

  .mb-sm-n6 {
    margin-bottom: -48px;
  }

  .mb-sm-n7 {
    margin-bottom: -74px;
  }

  .mb-sm-n60px {
    margin-bottom: -60px;
  }

  .ms-sm-0 {
    margin-left: 0;
  }

  .ms-sm-1 {
    margin-left: 4px;
  }

  .ms-sm-2 {
    margin-left: 8px;
  }

  .ms-sm-3 {
    margin-left: 16px;
  }

  .ms-sm-4 {
    margin-left: 24px;
  }

  .ms-sm-5 {
    margin-left: 32px;
  }

  .ms-sm-6 {
    margin-left: 48px;
  }

  .ms-sm-7 {
    margin-left: 74px;
  }

  .ms-sm-auto {
    margin-left: auto;
  }

  .p-sm-0 {
    padding: 0;
  }

  .p-sm-1 {
    padding: 4px;
  }

  .p-sm-2 {
    padding: 8px;
  }

  .p-sm-3 {
    padding: 16px;
  }

  .p-sm-4 {
    padding: 24px;
  }

  .p-sm-5 {
    padding: 32px;
  }

  .p-sm-6 {
    padding: 48px;
  }

  .p-sm-7 {
    padding: 74px;
  }

  .p-sm-1px {
    padding: 1px;
  }

  .p-sm-2px {
    padding: 2px;
  }

  .p-sm-20px {
    padding: 20px;
  }

  .p-sm-40px {
    padding: 40px;
  }

  .p-sm-60px {
    padding: 60px;
  }

  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-sm-1 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .px-sm-2 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .px-sm-3 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .px-sm-4 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .px-sm-5 {
    padding-right: 32px;
    padding-left: 32px;
  }

  .px-sm-6 {
    padding-right: 48px;
    padding-left: 48px;
  }

  .px-sm-7 {
    padding-right: 74px;
    padding-left: 74px;
  }

  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-sm-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .py-sm-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .py-sm-3 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .py-sm-4 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .py-sm-5 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .py-sm-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .py-sm-7 {
    padding-top: 74px;
    padding-bottom: 74px;
  }

  .pt-sm-0 {
    padding-top: 0;
  }

  .pt-sm-1 {
    padding-top: 4px;
  }

  .pt-sm-2 {
    padding-top: 8px;
  }

  .pt-sm-3 {
    padding-top: 16px;
  }

  .pt-sm-4 {
    padding-top: 24px;
  }

  .pt-sm-5 {
    padding-top: 32px;
  }

  .pt-sm-6 {
    padding-top: 48px;
  }

  .pt-sm-7 {
    padding-top: 74px;
  }

  .pe-sm-0 {
    padding-right: 0;
  }

  .pe-sm-1 {
    padding-right: 4px;
  }

  .pe-sm-2 {
    padding-right: 8px;
  }

  .pe-sm-3 {
    padding-right: 16px;
  }

  .pe-sm-4 {
    padding-right: 24px;
  }

  .pe-sm-5 {
    padding-right: 32px;
  }

  .pe-sm-6 {
    padding-right: 48px;
  }

  .pe-sm-7 {
    padding-right: 74px;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .pb-sm-1 {
    padding-bottom: 4px;
  }

  .pb-sm-2 {
    padding-bottom: 8px;
  }

  .pb-sm-3 {
    padding-bottom: 16px;
  }

  .pb-sm-4 {
    padding-bottom: 24px;
  }

  .pb-sm-5 {
    padding-bottom: 32px;
  }

  .pb-sm-6 {
    padding-bottom: 48px;
  }

  .pb-sm-7 {
    padding-bottom: 74px;
  }

  .ps-sm-0 {
    padding-left: 0;
  }

  .ps-sm-1 {
    padding-left: 4px;
  }

  .ps-sm-2 {
    padding-left: 8px;
  }

  .ps-sm-3 {
    padding-left: 16px;
  }

  .ps-sm-4 {
    padding-left: 24px;
  }

  .ps-sm-5 {
    padding-left: 32px;
  }

  .ps-sm-6 {
    padding-left: 48px;
  }

  .ps-sm-7 {
    padding-left: 74px;
  }

  .gap-sm-0 {
    gap: 0;
  }

  .gap-sm-1 {
    gap: 4px;
  }

  .gap-sm-2 {
    gap: 8px;
  }

  .gap-sm-3 {
    gap: 16px;
  }

  .gap-sm-4 {
    gap: 24px;
  }

  .gap-sm-5 {
    gap: 32px;
  }

  .gap-sm-6 {
    gap: 48px;
  }

  .gap-sm-7 {
    gap: 74px;
  }

  .row-gap-sm-0 {
    row-gap: 0;
  }

  .row-gap-sm-1 {
    row-gap: 4px;
  }

  .row-gap-sm-2 {
    row-gap: 8px;
  }

  .row-gap-sm-3 {
    row-gap: 16px;
  }

  .row-gap-sm-4 {
    row-gap: 24px;
  }

  .row-gap-sm-5 {
    row-gap: 32px;
  }

  .row-gap-sm-6 {
    row-gap: 48px;
  }

  .row-gap-sm-7 {
    row-gap: 74px;
  }

  .column-gap-sm-0 {
    column-gap: 0;
  }

  .column-gap-sm-1 {
    column-gap: 4px;
  }

  .column-gap-sm-2 {
    column-gap: 8px;
  }

  .column-gap-sm-3 {
    column-gap: 16px;
  }

  .column-gap-sm-4 {
    column-gap: 24px;
  }

  .column-gap-sm-5 {
    column-gap: 32px;
  }

  .column-gap-sm-6 {
    column-gap: 48px;
  }

  .column-gap-sm-7 {
    column-gap: 74px;
  }

  .fs-sm-1 {
    font-size: 64px;
  }

  .fs-sm-2 {
    font-size: 48px;
  }

  .fs-sm-3 {
    font-size: 42px;
  }

  .fs-sm-4 {
    font-size: 36px;
  }

  .fs-sm-5 {
    font-size: 24px;
  }

  .fs-sm-6 {
    font-size: 18px;
  }

  .fs-sm-7 {
    font-size: 14px;
  }

  .fw-sm-lighter {
    font-weight: lighter;
  }

  .fw-sm-light {
    font-weight: 300;
  }

  .fw-sm-normal {
    font-weight: 400;
  }

  .fw-sm-medium {
    font-weight: 500;
  }

  .fw-sm-semibold {
    font-weight: 600;
  }

  .fw-sm-bold {
    font-weight: 700;
  }

  .fw-sm-extra-bold {
    font-weight: 800;
  }

  .fw-sm-bolder {
    font-weight: 900;
  }

  .lh-sm-1 {
    line-height: 1;
  }

  .lh-sm-sm {
    line-height: 1.3333;
  }

  .lh-sm-base {
    line-height: 1.25;
  }

  .lh-sm-lg {
    line-height: 1.4;
  }

  .text-sm-start {
    text-align: left;
  }

  .text-sm-end {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }

  .rounded-sm {
    border-radius: var(--d-border-radius);
  }

  .rounded-sm-0 {
    border-radius: 0;
  }

  .rounded-sm-1 {
    border-radius: var(--d-border-radius-sm);
  }

  .rounded-sm-2 {
    border-radius: var(--d-border-radius);
  }

  .rounded-sm-3 {
    border-radius: var(--d-border-radius-lg);
  }

  .rounded-sm-4 {
    border-radius: var(--d-border-radius-xl);
  }

  .rounded-sm-5 {
    border-radius: var(--d-border-radius-xxl);
  }

  .rounded-sm-6 {
    border-radius: var(--d-border-radius-3xl);
  }

  .rounded-sm-7 {
    border-radius: var(--d-border-radius-4xl);
  }

  .rounded-sm-circle {
    border-radius: 50%;
  }

  .rounded-sm-pill {
    border-radius: var(--d-border-radius-pill);
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline;
  }

  .d-md-inline-block {
    display: inline-block;
  }

  .d-md-block {
    display: block;
  }

  .d-md-grid {
    display: grid;
  }

  .d-md-inline-grid {
    display: inline-grid;
  }

  .d-md-table {
    display: table;
  }

  .d-md-table-row {
    display: table-row;
  }

  .d-md-table-cell {
    display: table-cell;
  }

  .d-md-flex {
    display: flex;
  }

  .d-md-inline-flex {
    display: inline-flex;
  }

  .d-md-none {
    display: none;
  }

  .position-md-static {
    position: static;
  }

  .position-md-relative {
    position: relative;
  }

  .position-md-absolute {
    position: absolute;
  }

  .position-md-fixed {
    position: fixed;
  }

  .position-md-sticky {
    position: sticky;
  }

  .w-md-72px {
    width: 72px;
  }

  .w-md-140px {
    width: 140px;
  }

  .w-md-180px {
    width: 180px;
  }

  .w-md-25 {
    width: 25%;
  }

  .w-md-50 {
    width: 50%;
  }

  .w-md-75 {
    width: 75%;
  }

  .w-md-100 {
    width: 100%;
  }

  .w-md-auto {
    width: auto;
  }

  .mw-md-240px {
    max-width: 240px;
  }

  .mw-md-250px {
    max-width: 250px;
  }

  .mw-md-280px {
    max-width: 280px;
  }

  .mw-md-300px {
    max-width: 300px;
  }

  .mw-md-400px {
    max-width: 400px;
  }

  .mw-md-450px {
    max-width: 450px;
  }

  .mw-md-650px {
    max-width: 650px;
  }

  .mw-md-70 {
    max-width: 70%;
  }

  .mw-md-100 {
    max-width: 100%;
  }

  .h-md-60px {
    height: 60px;
  }

  .h-md-72px {
    height: 72px;
  }

  .h-md-80px {
    height: 80px;
  }

  .h-md-140px {
    height: 140px;
  }

  .h-md-180px {
    height: 180px;
  }

  .h-md-25 {
    height: 25%;
  }

  .h-md-50 {
    height: 50%;
  }

  .h-md-75 {
    height: 75%;
  }

  .h-md-100 {
    height: 100%;
  }

  .h-md-auto {
    height: auto;
  }

  .flex-md-fill {
    flex: 1 1 auto;
  }

  .flex-md-equal {
    flex: 1;
  }

  .flex-md-row {
    flex-direction: row;
  }

  .flex-md-column {
    flex-direction: column;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-md-grow-0 {
    flex-grow: 0;
  }

  .flex-md-grow-1 {
    flex-grow: 1;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-md-start {
    justify-content: flex-start;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .justify-content-md-center {
    justify-content: center;
  }

  .justify-content-md-between {
    justify-content: space-between;
  }

  .justify-content-md-around {
    justify-content: space-around;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly;
  }

  .align-items-md-start {
    align-items: flex-start;
  }

  .align-items-md-end {
    align-items: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .align-items-md-baseline {
    align-items: baseline;
  }

  .align-items-md-stretch {
    align-items: stretch;
  }

  .align-self-md-auto {
    align-self: auto;
  }

  .align-self-md-start {
    align-self: flex-start;
  }

  .align-self-md-end {
    align-self: flex-end;
  }

  .align-self-md-center {
    align-self: center;
  }

  .align-self-md-baseline {
    align-self: baseline;
  }

  .align-self-md-stretch {
    align-self: stretch;
  }

  .m-md-0 {
    margin: 0;
  }

  .m-md-1 {
    margin: 4px;
  }

  .m-md-2 {
    margin: 8px;
  }

  .m-md-3 {
    margin: 16px;
  }

  .m-md-4 {
    margin: 24px;
  }

  .m-md-5 {
    margin: 32px;
  }

  .m-md-6 {
    margin: 48px;
  }

  .m-md-7 {
    margin: 74px;
  }

  .m-md-auto {
    margin: auto;
  }

  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-md-1 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .mx-md-2 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .mx-md-3 {
    margin-right: 16px;
    margin-left: 16px;
  }

  .mx-md-4 {
    margin-right: 24px;
    margin-left: 24px;
  }

  .mx-md-5 {
    margin-right: 32px;
    margin-left: 32px;
  }

  .mx-md-6 {
    margin-right: 48px;
    margin-left: 48px;
  }

  .mx-md-7 {
    margin-right: 74px;
    margin-left: 74px;
  }

  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-md-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .my-md-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .my-md-3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .my-md-4 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .my-md-5 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .my-md-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .my-md-7 {
    margin-top: 74px;
    margin-bottom: 74px;
  }

  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-md-0 {
    margin-top: 0;
  }

  .mt-md-1 {
    margin-top: 4px;
  }

  .mt-md-2 {
    margin-top: 8px;
  }

  .mt-md-3 {
    margin-top: 16px;
  }

  .mt-md-4 {
    margin-top: 24px;
  }

  .mt-md-5 {
    margin-top: 32px;
  }

  .mt-md-6 {
    margin-top: 48px;
  }

  .mt-md-7 {
    margin-top: 74px;
  }

  .mt-md-auto {
    margin-top: auto;
  }

  .mt-md-40px {
    margin-top: 40px;
  }

  .mt-md-60px {
    margin-top: 60px;
  }

  .mt-md-80px {
    margin-top: 80px;
  }

  .mt-md-n7 {
    margin-top: -74px;
  }

  .me-md-0 {
    margin-right: 0;
  }

  .me-md-1 {
    margin-right: 4px;
  }

  .me-md-2 {
    margin-right: 8px;
  }

  .me-md-3 {
    margin-right: 16px;
  }

  .me-md-4 {
    margin-right: 24px;
  }

  .me-md-5 {
    margin-right: 32px;
  }

  .me-md-6 {
    margin-right: 48px;
  }

  .me-md-7 {
    margin-right: 74px;
  }

  .me-md-auto {
    margin-right: auto;
  }

  .mb-md-0 {
    margin-bottom: 0;
  }

  .mb-md-1 {
    margin-bottom: 4px;
  }

  .mb-md-2 {
    margin-bottom: 8px;
  }

  .mb-md-3 {
    margin-bottom: 16px;
  }

  .mb-md-4 {
    margin-bottom: 24px;
  }

  .mb-md-5 {
    margin-bottom: 32px;
  }

  .mb-md-6 {
    margin-bottom: 48px;
  }

  .mb-md-7 {
    margin-bottom: 74px;
  }

  .mb-md-auto {
    margin-bottom: auto;
  }

  .mb-md-40px {
    margin-bottom: 40px;
  }

  .mb-md-80px {
    margin-bottom: 80px;
  }

  .mb-md-n4 {
    margin-bottom: -24px;
  }

  .mb-md-n6 {
    margin-bottom: -48px;
  }

  .mb-md-n7 {
    margin-bottom: -74px;
  }

  .mb-md-n60px {
    margin-bottom: -60px;
  }

  .ms-md-0 {
    margin-left: 0;
  }

  .ms-md-1 {
    margin-left: 4px;
  }

  .ms-md-2 {
    margin-left: 8px;
  }

  .ms-md-3 {
    margin-left: 16px;
  }

  .ms-md-4 {
    margin-left: 24px;
  }

  .ms-md-5 {
    margin-left: 32px;
  }

  .ms-md-6 {
    margin-left: 48px;
  }

  .ms-md-7 {
    margin-left: 74px;
  }

  .ms-md-auto {
    margin-left: auto;
  }

  .p-md-0 {
    padding: 0;
  }

  .p-md-1 {
    padding: 4px;
  }

  .p-md-2 {
    padding: 8px;
  }

  .p-md-3 {
    padding: 16px;
  }

  .p-md-4 {
    padding: 24px;
  }

  .p-md-5 {
    padding: 32px;
  }

  .p-md-6 {
    padding: 48px;
  }

  .p-md-7 {
    padding: 74px;
  }

  .p-md-1px {
    padding: 1px;
  }

  .p-md-2px {
    padding: 2px;
  }

  .p-md-20px {
    padding: 20px;
  }

  .p-md-40px {
    padding: 40px;
  }

  .p-md-60px {
    padding: 60px;
  }

  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-md-1 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .px-md-2 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .px-md-3 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .px-md-4 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .px-md-5 {
    padding-right: 32px;
    padding-left: 32px;
  }

  .px-md-6 {
    padding-right: 48px;
    padding-left: 48px;
  }

  .px-md-7 {
    padding-right: 74px;
    padding-left: 74px;
  }

  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-md-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .py-md-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .py-md-3 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .py-md-4 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .py-md-5 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .py-md-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .py-md-7 {
    padding-top: 74px;
    padding-bottom: 74px;
  }

  .pt-md-0 {
    padding-top: 0;
  }

  .pt-md-1 {
    padding-top: 4px;
  }

  .pt-md-2 {
    padding-top: 8px;
  }

  .pt-md-3 {
    padding-top: 16px;
  }

  .pt-md-4 {
    padding-top: 24px;
  }

  .pt-md-5 {
    padding-top: 32px;
  }

  .pt-md-6 {
    padding-top: 48px;
  }

  .pt-md-7 {
    padding-top: 74px;
  }

  .pe-md-0 {
    padding-right: 0;
  }

  .pe-md-1 {
    padding-right: 4px;
  }

  .pe-md-2 {
    padding-right: 8px;
  }

  .pe-md-3 {
    padding-right: 16px;
  }

  .pe-md-4 {
    padding-right: 24px;
  }

  .pe-md-5 {
    padding-right: 32px;
  }

  .pe-md-6 {
    padding-right: 48px;
  }

  .pe-md-7 {
    padding-right: 74px;
  }

  .pb-md-0 {
    padding-bottom: 0;
  }

  .pb-md-1 {
    padding-bottom: 4px;
  }

  .pb-md-2 {
    padding-bottom: 8px;
  }

  .pb-md-3 {
    padding-bottom: 16px;
  }

  .pb-md-4 {
    padding-bottom: 24px;
  }

  .pb-md-5 {
    padding-bottom: 32px;
  }

  .pb-md-6 {
    padding-bottom: 48px;
  }

  .pb-md-7 {
    padding-bottom: 74px;
  }

  .ps-md-0 {
    padding-left: 0;
  }

  .ps-md-1 {
    padding-left: 4px;
  }

  .ps-md-2 {
    padding-left: 8px;
  }

  .ps-md-3 {
    padding-left: 16px;
  }

  .ps-md-4 {
    padding-left: 24px;
  }

  .ps-md-5 {
    padding-left: 32px;
  }

  .ps-md-6 {
    padding-left: 48px;
  }

  .ps-md-7 {
    padding-left: 74px;
  }

  .gap-md-0 {
    gap: 0;
  }

  .gap-md-1 {
    gap: 4px;
  }

  .gap-md-2 {
    gap: 8px;
  }

  .gap-md-3 {
    gap: 16px;
  }

  .gap-md-4 {
    gap: 24px;
  }

  .gap-md-5 {
    gap: 32px;
  }

  .gap-md-6 {
    gap: 48px;
  }

  .gap-md-7 {
    gap: 74px;
  }

  .row-gap-md-0 {
    row-gap: 0;
  }

  .row-gap-md-1 {
    row-gap: 4px;
  }

  .row-gap-md-2 {
    row-gap: 8px;
  }

  .row-gap-md-3 {
    row-gap: 16px;
  }

  .row-gap-md-4 {
    row-gap: 24px;
  }

  .row-gap-md-5 {
    row-gap: 32px;
  }

  .row-gap-md-6 {
    row-gap: 48px;
  }

  .row-gap-md-7 {
    row-gap: 74px;
  }

  .column-gap-md-0 {
    column-gap: 0;
  }

  .column-gap-md-1 {
    column-gap: 4px;
  }

  .column-gap-md-2 {
    column-gap: 8px;
  }

  .column-gap-md-3 {
    column-gap: 16px;
  }

  .column-gap-md-4 {
    column-gap: 24px;
  }

  .column-gap-md-5 {
    column-gap: 32px;
  }

  .column-gap-md-6 {
    column-gap: 48px;
  }

  .column-gap-md-7 {
    column-gap: 74px;
  }

  .fs-md-1 {
    font-size: 64px;
  }

  .fs-md-2 {
    font-size: 48px;
  }

  .fs-md-3 {
    font-size: 42px;
  }

  .fs-md-4 {
    font-size: 36px;
  }

  .fs-md-5 {
    font-size: 24px;
  }

  .fs-md-6 {
    font-size: 18px;
  }

  .fs-md-7 {
    font-size: 14px;
  }

  .fw-md-lighter {
    font-weight: lighter;
  }

  .fw-md-light {
    font-weight: 300;
  }

  .fw-md-normal {
    font-weight: 400;
  }

  .fw-md-medium {
    font-weight: 500;
  }

  .fw-md-semibold {
    font-weight: 600;
  }

  .fw-md-bold {
    font-weight: 700;
  }

  .fw-md-extra-bold {
    font-weight: 800;
  }

  .fw-md-bolder {
    font-weight: 900;
  }

  .lh-md-1 {
    line-height: 1;
  }

  .lh-md-sm {
    line-height: 1.3333;
  }

  .lh-md-base {
    line-height: 1.25;
  }

  .lh-md-lg {
    line-height: 1.4;
  }

  .text-md-start {
    text-align: left;
  }

  .text-md-end {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }

  .rounded-md {
    border-radius: var(--d-border-radius);
  }

  .rounded-md-0 {
    border-radius: 0;
  }

  .rounded-md-1 {
    border-radius: var(--d-border-radius-sm);
  }

  .rounded-md-2 {
    border-radius: var(--d-border-radius);
  }

  .rounded-md-3 {
    border-radius: var(--d-border-radius-lg);
  }

  .rounded-md-4 {
    border-radius: var(--d-border-radius-xl);
  }

  .rounded-md-5 {
    border-radius: var(--d-border-radius-xxl);
  }

  .rounded-md-6 {
    border-radius: var(--d-border-radius-3xl);
  }

  .rounded-md-7 {
    border-radius: var(--d-border-radius-4xl);
  }

  .rounded-md-circle {
    border-radius: 50%;
  }

  .rounded-md-pill {
    border-radius: var(--d-border-radius-pill);
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-grid {
    display: grid;
  }

  .d-lg-inline-grid {
    display: inline-grid;
  }

  .d-lg-table {
    display: table;
  }

  .d-lg-table-row {
    display: table-row;
  }

  .d-lg-table-cell {
    display: table-cell;
  }

  .d-lg-flex {
    display: flex;
  }

  .d-lg-inline-flex {
    display: inline-flex;
  }

  .d-lg-none {
    display: none;
  }

  .position-lg-static {
    position: static;
  }

  .position-lg-relative {
    position: relative;
  }

  .position-lg-absolute {
    position: absolute;
  }

  .position-lg-fixed {
    position: fixed;
  }

  .position-lg-sticky {
    position: sticky;
  }

  .w-lg-72px {
    width: 72px;
  }

  .w-lg-140px {
    width: 140px;
  }

  .w-lg-180px {
    width: 180px;
  }

  .w-lg-25 {
    width: 25%;
  }

  .w-lg-50 {
    width: 50%;
  }

  .w-lg-75 {
    width: 75%;
  }

  .w-lg-100 {
    width: 100%;
  }

  .w-lg-auto {
    width: auto;
  }

  .mw-lg-240px {
    max-width: 240px;
  }

  .mw-lg-250px {
    max-width: 250px;
  }

  .mw-lg-280px {
    max-width: 280px;
  }

  .mw-lg-300px {
    max-width: 300px;
  }

  .mw-lg-400px {
    max-width: 400px;
  }

  .mw-lg-450px {
    max-width: 450px;
  }

  .mw-lg-650px {
    max-width: 650px;
  }

  .mw-lg-70 {
    max-width: 70%;
  }

  .mw-lg-100 {
    max-width: 100%;
  }

  .h-lg-60px {
    height: 60px;
  }

  .h-lg-72px {
    height: 72px;
  }

  .h-lg-80px {
    height: 80px;
  }

  .h-lg-140px {
    height: 140px;
  }

  .h-lg-180px {
    height: 180px;
  }

  .h-lg-25 {
    height: 25%;
  }

  .h-lg-50 {
    height: 50%;
  }

  .h-lg-75 {
    height: 75%;
  }

  .h-lg-100 {
    height: 100%;
  }

  .h-lg-auto {
    height: auto;
  }

  .flex-lg-fill {
    flex: 1 1 auto;
  }

  .flex-lg-equal {
    flex: 1;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .flex-lg-column {
    flex-direction: column;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-lg-grow-0 {
    flex-grow: 0;
  }

  .flex-lg-grow-1 {
    flex-grow: 1;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }

  .flex-lg-wrap {
    flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-lg-start {
    justify-content: flex-start;
  }

  .justify-content-lg-end {
    justify-content: flex-end;
  }

  .justify-content-lg-center {
    justify-content: center;
  }

  .justify-content-lg-between {
    justify-content: space-between;
  }

  .justify-content-lg-around {
    justify-content: space-around;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }

  .align-items-lg-start {
    align-items: flex-start;
  }

  .align-items-lg-end {
    align-items: flex-end;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .align-items-lg-baseline {
    align-items: baseline;
  }

  .align-items-lg-stretch {
    align-items: stretch;
  }

  .align-self-lg-auto {
    align-self: auto;
  }

  .align-self-lg-start {
    align-self: flex-start;
  }

  .align-self-lg-end {
    align-self: flex-end;
  }

  .align-self-lg-center {
    align-self: center;
  }

  .align-self-lg-baseline {
    align-self: baseline;
  }

  .align-self-lg-stretch {
    align-self: stretch;
  }

  .m-lg-0 {
    margin: 0;
  }

  .m-lg-1 {
    margin: 4px;
  }

  .m-lg-2 {
    margin: 8px;
  }

  .m-lg-3 {
    margin: 16px;
  }

  .m-lg-4 {
    margin: 24px;
  }

  .m-lg-5 {
    margin: 32px;
  }

  .m-lg-6 {
    margin: 48px;
  }

  .m-lg-7 {
    margin: 74px;
  }

  .m-lg-auto {
    margin: auto;
  }

  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-lg-1 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .mx-lg-2 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .mx-lg-3 {
    margin-right: 16px;
    margin-left: 16px;
  }

  .mx-lg-4 {
    margin-right: 24px;
    margin-left: 24px;
  }

  .mx-lg-5 {
    margin-right: 32px;
    margin-left: 32px;
  }

  .mx-lg-6 {
    margin-right: 48px;
    margin-left: 48px;
  }

  .mx-lg-7 {
    margin-right: 74px;
    margin-left: 74px;
  }

  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-lg-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .my-lg-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .my-lg-3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .my-lg-4 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .my-lg-5 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .my-lg-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .my-lg-7 {
    margin-top: 74px;
    margin-bottom: 74px;
  }

  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-lg-0 {
    margin-top: 0;
  }

  .mt-lg-1 {
    margin-top: 4px;
  }

  .mt-lg-2 {
    margin-top: 8px;
  }

  .mt-lg-3 {
    margin-top: 16px;
  }

  .mt-lg-4 {
    margin-top: 24px;
  }

  .mt-lg-5 {
    margin-top: 32px;
  }

  .mt-lg-6 {
    margin-top: 48px;
  }

  .mt-lg-7 {
    margin-top: 74px;
  }

  .mt-lg-auto {
    margin-top: auto;
  }

  .mt-lg-40px {
    margin-top: 40px;
  }

  .mt-lg-60px {
    margin-top: 60px;
  }

  .mt-lg-80px {
    margin-top: 80px;
  }

  .mt-lg-n7 {
    margin-top: -74px;
  }

  .me-lg-0 {
    margin-right: 0;
  }

  .me-lg-1 {
    margin-right: 4px;
  }

  .me-lg-2 {
    margin-right: 8px;
  }

  .me-lg-3 {
    margin-right: 16px;
  }

  .me-lg-4 {
    margin-right: 24px;
  }

  .me-lg-5 {
    margin-right: 32px;
  }

  .me-lg-6 {
    margin-right: 48px;
  }

  .me-lg-7 {
    margin-right: 74px;
  }

  .me-lg-auto {
    margin-right: auto;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }

  .mb-lg-1 {
    margin-bottom: 4px;
  }

  .mb-lg-2 {
    margin-bottom: 8px;
  }

  .mb-lg-3 {
    margin-bottom: 16px;
  }

  .mb-lg-4 {
    margin-bottom: 24px;
  }

  .mb-lg-5 {
    margin-bottom: 32px;
  }

  .mb-lg-6 {
    margin-bottom: 48px;
  }

  .mb-lg-7 {
    margin-bottom: 74px;
  }

  .mb-lg-auto {
    margin-bottom: auto;
  }

  .mb-lg-40px {
    margin-bottom: 40px;
  }

  .mb-lg-80px {
    margin-bottom: 80px;
  }

  .mb-lg-n4 {
    margin-bottom: -24px;
  }

  .mb-lg-n6 {
    margin-bottom: -48px;
  }

  .mb-lg-n7 {
    margin-bottom: -74px;
  }

  .mb-lg-n60px {
    margin-bottom: -60px;
  }

  .ms-lg-0 {
    margin-left: 0;
  }

  .ms-lg-1 {
    margin-left: 4px;
  }

  .ms-lg-2 {
    margin-left: 8px;
  }

  .ms-lg-3 {
    margin-left: 16px;
  }

  .ms-lg-4 {
    margin-left: 24px;
  }

  .ms-lg-5 {
    margin-left: 32px;
  }

  .ms-lg-6 {
    margin-left: 48px;
  }

  .ms-lg-7 {
    margin-left: 74px;
  }

  .ms-lg-auto {
    margin-left: auto;
  }

  .p-lg-0 {
    padding: 0;
  }

  .p-lg-1 {
    padding: 4px;
  }

  .p-lg-2 {
    padding: 8px;
  }

  .p-lg-3 {
    padding: 16px;
  }

  .p-lg-4 {
    padding: 24px;
  }

  .p-lg-5 {
    padding: 32px;
  }

  .p-lg-6 {
    padding: 48px;
  }

  .p-lg-7 {
    padding: 74px;
  }

  .p-lg-1px {
    padding: 1px;
  }

  .p-lg-2px {
    padding: 2px;
  }

  .p-lg-20px {
    padding: 20px;
  }

  .p-lg-40px {
    padding: 40px;
  }

  .p-lg-60px {
    padding: 60px;
  }

  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-lg-1 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .px-lg-2 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .px-lg-3 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .px-lg-4 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .px-lg-5 {
    padding-right: 32px;
    padding-left: 32px;
  }

  .px-lg-6 {
    padding-right: 48px;
    padding-left: 48px;
  }

  .px-lg-7 {
    padding-right: 74px;
    padding-left: 74px;
  }

  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-lg-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .py-lg-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .py-lg-3 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .py-lg-4 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .py-lg-5 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .py-lg-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .py-lg-7 {
    padding-top: 74px;
    padding-bottom: 74px;
  }

  .pt-lg-0 {
    padding-top: 0;
  }

  .pt-lg-1 {
    padding-top: 4px;
  }

  .pt-lg-2 {
    padding-top: 8px;
  }

  .pt-lg-3 {
    padding-top: 16px;
  }

  .pt-lg-4 {
    padding-top: 24px;
  }

  .pt-lg-5 {
    padding-top: 32px;
  }

  .pt-lg-6 {
    padding-top: 48px;
  }

  .pt-lg-7 {
    padding-top: 74px;
  }

  .pe-lg-0 {
    padding-right: 0;
  }

  .pe-lg-1 {
    padding-right: 4px;
  }

  .pe-lg-2 {
    padding-right: 8px;
  }

  .pe-lg-3 {
    padding-right: 16px;
  }

  .pe-lg-4 {
    padding-right: 24px;
  }

  .pe-lg-5 {
    padding-right: 32px;
  }

  .pe-lg-6 {
    padding-right: 48px;
  }

  .pe-lg-7 {
    padding-right: 74px;
  }

  .pb-lg-0 {
    padding-bottom: 0;
  }

  .pb-lg-1 {
    padding-bottom: 4px;
  }

  .pb-lg-2 {
    padding-bottom: 8px;
  }

  .pb-lg-3 {
    padding-bottom: 16px;
  }

  .pb-lg-4 {
    padding-bottom: 24px;
  }

  .pb-lg-5 {
    padding-bottom: 32px;
  }

  .pb-lg-6 {
    padding-bottom: 48px;
  }

  .pb-lg-7 {
    padding-bottom: 74px;
  }

  .ps-lg-0 {
    padding-left: 0;
  }

  .ps-lg-1 {
    padding-left: 4px;
  }

  .ps-lg-2 {
    padding-left: 8px;
  }

  .ps-lg-3 {
    padding-left: 16px;
  }

  .ps-lg-4 {
    padding-left: 24px;
  }

  .ps-lg-5 {
    padding-left: 32px;
  }

  .ps-lg-6 {
    padding-left: 48px;
  }

  .ps-lg-7 {
    padding-left: 74px;
  }

  .gap-lg-0 {
    gap: 0;
  }

  .gap-lg-1 {
    gap: 4px;
  }

  .gap-lg-2 {
    gap: 8px;
  }

  .gap-lg-3 {
    gap: 16px;
  }

  .gap-lg-4 {
    gap: 24px;
  }

  .gap-lg-5 {
    gap: 32px;
  }

  .gap-lg-6 {
    gap: 48px;
  }

  .gap-lg-7 {
    gap: 74px;
  }

  .row-gap-lg-0 {
    row-gap: 0;
  }

  .row-gap-lg-1 {
    row-gap: 4px;
  }

  .row-gap-lg-2 {
    row-gap: 8px;
  }

  .row-gap-lg-3 {
    row-gap: 16px;
  }

  .row-gap-lg-4 {
    row-gap: 24px;
  }

  .row-gap-lg-5 {
    row-gap: 32px;
  }

  .row-gap-lg-6 {
    row-gap: 48px;
  }

  .row-gap-lg-7 {
    row-gap: 74px;
  }

  .column-gap-lg-0 {
    column-gap: 0;
  }

  .column-gap-lg-1 {
    column-gap: 4px;
  }

  .column-gap-lg-2 {
    column-gap: 8px;
  }

  .column-gap-lg-3 {
    column-gap: 16px;
  }

  .column-gap-lg-4 {
    column-gap: 24px;
  }

  .column-gap-lg-5 {
    column-gap: 32px;
  }

  .column-gap-lg-6 {
    column-gap: 48px;
  }

  .column-gap-lg-7 {
    column-gap: 74px;
  }

  .fs-lg-1 {
    font-size: 64px;
  }

  .fs-lg-2 {
    font-size: 48px;
  }

  .fs-lg-3 {
    font-size: 42px;
  }

  .fs-lg-4 {
    font-size: 36px;
  }

  .fs-lg-5 {
    font-size: 24px;
  }

  .fs-lg-6 {
    font-size: 18px;
  }

  .fs-lg-7 {
    font-size: 14px;
  }

  .fw-lg-lighter {
    font-weight: lighter;
  }

  .fw-lg-light {
    font-weight: 300;
  }

  .fw-lg-normal {
    font-weight: 400;
  }

  .fw-lg-medium {
    font-weight: 500;
  }

  .fw-lg-semibold {
    font-weight: 600;
  }

  .fw-lg-bold {
    font-weight: 700;
  }

  .fw-lg-extra-bold {
    font-weight: 800;
  }

  .fw-lg-bolder {
    font-weight: 900;
  }

  .lh-lg-1 {
    line-height: 1;
  }

  .lh-lg-sm {
    line-height: 1.3333;
  }

  .lh-lg-base {
    line-height: 1.25;
  }

  .lh-lg-lg {
    line-height: 1.4;
  }

  .text-lg-start {
    text-align: left;
  }

  .text-lg-end {
    text-align: right;
  }

  .text-lg-center {
    text-align: center;
  }

  .rounded-lg {
    border-radius: var(--d-border-radius);
  }

  .rounded-lg-0 {
    border-radius: 0;
  }

  .rounded-lg-1 {
    border-radius: var(--d-border-radius-sm);
  }

  .rounded-lg-2 {
    border-radius: var(--d-border-radius);
  }

  .rounded-lg-3 {
    border-radius: var(--d-border-radius-lg);
  }

  .rounded-lg-4 {
    border-radius: var(--d-border-radius-xl);
  }

  .rounded-lg-5 {
    border-radius: var(--d-border-radius-xxl);
  }

  .rounded-lg-6 {
    border-radius: var(--d-border-radius-3xl);
  }

  .rounded-lg-7 {
    border-radius: var(--d-border-radius-4xl);
  }

  .rounded-lg-circle {
    border-radius: 50%;
  }

  .rounded-lg-pill {
    border-radius: var(--d-border-radius-pill);
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-grid {
    display: grid;
  }

  .d-xl-inline-grid {
    display: inline-grid;
  }

  .d-xl-table {
    display: table;
  }

  .d-xl-table-row {
    display: table-row;
  }

  .d-xl-table-cell {
    display: table-cell;
  }

  .d-xl-flex {
    display: flex;
  }

  .d-xl-inline-flex {
    display: inline-flex;
  }

  .d-xl-none {
    display: none;
  }

  .position-xl-static {
    position: static;
  }

  .position-xl-relative {
    position: relative;
  }

  .position-xl-absolute {
    position: absolute;
  }

  .position-xl-fixed {
    position: fixed;
  }

  .position-xl-sticky {
    position: sticky;
  }

  .w-xl-72px {
    width: 72px;
  }

  .w-xl-140px {
    width: 140px;
  }

  .w-xl-180px {
    width: 180px;
  }

  .w-xl-25 {
    width: 25%;
  }

  .w-xl-50 {
    width: 50%;
  }

  .w-xl-75 {
    width: 75%;
  }

  .w-xl-100 {
    width: 100%;
  }

  .w-xl-auto {
    width: auto;
  }

  .mw-xl-240px {
    max-width: 240px;
  }

  .mw-xl-250px {
    max-width: 250px;
  }

  .mw-xl-280px {
    max-width: 280px;
  }

  .mw-xl-300px {
    max-width: 300px;
  }

  .mw-xl-400px {
    max-width: 400px;
  }

  .mw-xl-450px {
    max-width: 450px;
  }

  .mw-xl-650px {
    max-width: 650px;
  }

  .mw-xl-70 {
    max-width: 70%;
  }

  .mw-xl-100 {
    max-width: 100%;
  }

  .h-xl-60px {
    height: 60px;
  }

  .h-xl-72px {
    height: 72px;
  }

  .h-xl-80px {
    height: 80px;
  }

  .h-xl-140px {
    height: 140px;
  }

  .h-xl-180px {
    height: 180px;
  }

  .h-xl-25 {
    height: 25%;
  }

  .h-xl-50 {
    height: 50%;
  }

  .h-xl-75 {
    height: 75%;
  }

  .h-xl-100 {
    height: 100%;
  }

  .h-xl-auto {
    height: auto;
  }

  .flex-xl-fill {
    flex: 1 1 auto;
  }

  .flex-xl-equal {
    flex: 1;
  }

  .flex-xl-row {
    flex-direction: row;
  }

  .flex-xl-column {
    flex-direction: column;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xl-grow-0 {
    flex-grow: 0;
  }

  .flex-xl-grow-1 {
    flex-grow: 1;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }

  .flex-xl-wrap {
    flex-wrap: wrap;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-xl-start {
    justify-content: flex-start;
  }

  .justify-content-xl-end {
    justify-content: flex-end;
  }

  .justify-content-xl-center {
    justify-content: center;
  }

  .justify-content-xl-between {
    justify-content: space-between;
  }

  .justify-content-xl-around {
    justify-content: space-around;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }

  .align-items-xl-start {
    align-items: flex-start;
  }

  .align-items-xl-end {
    align-items: flex-end;
  }

  .align-items-xl-center {
    align-items: center;
  }

  .align-items-xl-baseline {
    align-items: baseline;
  }

  .align-items-xl-stretch {
    align-items: stretch;
  }

  .align-self-xl-auto {
    align-self: auto;
  }

  .align-self-xl-start {
    align-self: flex-start;
  }

  .align-self-xl-end {
    align-self: flex-end;
  }

  .align-self-xl-center {
    align-self: center;
  }

  .align-self-xl-baseline {
    align-self: baseline;
  }

  .align-self-xl-stretch {
    align-self: stretch;
  }

  .m-xl-0 {
    margin: 0;
  }

  .m-xl-1 {
    margin: 4px;
  }

  .m-xl-2 {
    margin: 8px;
  }

  .m-xl-3 {
    margin: 16px;
  }

  .m-xl-4 {
    margin: 24px;
  }

  .m-xl-5 {
    margin: 32px;
  }

  .m-xl-6 {
    margin: 48px;
  }

  .m-xl-7 {
    margin: 74px;
  }

  .m-xl-auto {
    margin: auto;
  }

  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-xl-1 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .mx-xl-2 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .mx-xl-3 {
    margin-right: 16px;
    margin-left: 16px;
  }

  .mx-xl-4 {
    margin-right: 24px;
    margin-left: 24px;
  }

  .mx-xl-5 {
    margin-right: 32px;
    margin-left: 32px;
  }

  .mx-xl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }

  .mx-xl-7 {
    margin-right: 74px;
    margin-left: 74px;
  }

  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xl-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .my-xl-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .my-xl-3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .my-xl-4 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .my-xl-5 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .my-xl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .my-xl-7 {
    margin-top: 74px;
    margin-bottom: 74px;
  }

  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xl-0 {
    margin-top: 0;
  }

  .mt-xl-1 {
    margin-top: 4px;
  }

  .mt-xl-2 {
    margin-top: 8px;
  }

  .mt-xl-3 {
    margin-top: 16px;
  }

  .mt-xl-4 {
    margin-top: 24px;
  }

  .mt-xl-5 {
    margin-top: 32px;
  }

  .mt-xl-6 {
    margin-top: 48px;
  }

  .mt-xl-7 {
    margin-top: 74px;
  }

  .mt-xl-auto {
    margin-top: auto;
  }

  .mt-xl-40px {
    margin-top: 40px;
  }

  .mt-xl-60px {
    margin-top: 60px;
  }

  .mt-xl-80px {
    margin-top: 80px;
  }

  .mt-xl-n7 {
    margin-top: -74px;
  }

  .me-xl-0 {
    margin-right: 0;
  }

  .me-xl-1 {
    margin-right: 4px;
  }

  .me-xl-2 {
    margin-right: 8px;
  }

  .me-xl-3 {
    margin-right: 16px;
  }

  .me-xl-4 {
    margin-right: 24px;
  }

  .me-xl-5 {
    margin-right: 32px;
  }

  .me-xl-6 {
    margin-right: 48px;
  }

  .me-xl-7 {
    margin-right: 74px;
  }

  .me-xl-auto {
    margin-right: auto;
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }

  .mb-xl-1 {
    margin-bottom: 4px;
  }

  .mb-xl-2 {
    margin-bottom: 8px;
  }

  .mb-xl-3 {
    margin-bottom: 16px;
  }

  .mb-xl-4 {
    margin-bottom: 24px;
  }

  .mb-xl-5 {
    margin-bottom: 32px;
  }

  .mb-xl-6 {
    margin-bottom: 48px;
  }

  .mb-xl-7 {
    margin-bottom: 74px;
  }

  .mb-xl-auto {
    margin-bottom: auto;
  }

  .mb-xl-40px {
    margin-bottom: 40px;
  }

  .mb-xl-80px {
    margin-bottom: 80px;
  }

  .mb-xl-n4 {
    margin-bottom: -24px;
  }

  .mb-xl-n6 {
    margin-bottom: -48px;
  }

  .mb-xl-n7 {
    margin-bottom: -74px;
  }

  .mb-xl-n60px {
    margin-bottom: -60px;
  }

  .ms-xl-0 {
    margin-left: 0;
  }

  .ms-xl-1 {
    margin-left: 4px;
  }

  .ms-xl-2 {
    margin-left: 8px;
  }

  .ms-xl-3 {
    margin-left: 16px;
  }

  .ms-xl-4 {
    margin-left: 24px;
  }

  .ms-xl-5 {
    margin-left: 32px;
  }

  .ms-xl-6 {
    margin-left: 48px;
  }

  .ms-xl-7 {
    margin-left: 74px;
  }

  .ms-xl-auto {
    margin-left: auto;
  }

  .p-xl-0 {
    padding: 0;
  }

  .p-xl-1 {
    padding: 4px;
  }

  .p-xl-2 {
    padding: 8px;
  }

  .p-xl-3 {
    padding: 16px;
  }

  .p-xl-4 {
    padding: 24px;
  }

  .p-xl-5 {
    padding: 32px;
  }

  .p-xl-6 {
    padding: 48px;
  }

  .p-xl-7 {
    padding: 74px;
  }

  .p-xl-1px {
    padding: 1px;
  }

  .p-xl-2px {
    padding: 2px;
  }

  .p-xl-20px {
    padding: 20px;
  }

  .p-xl-40px {
    padding: 40px;
  }

  .p-xl-60px {
    padding: 60px;
  }

  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-xl-1 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .px-xl-2 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .px-xl-3 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .px-xl-4 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .px-xl-5 {
    padding-right: 32px;
    padding-left: 32px;
  }

  .px-xl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }

  .px-xl-7 {
    padding-right: 74px;
    padding-left: 74px;
  }

  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xl-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .py-xl-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .py-xl-3 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .py-xl-4 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .py-xl-5 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .py-xl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .py-xl-7 {
    padding-top: 74px;
    padding-bottom: 74px;
  }

  .pt-xl-0 {
    padding-top: 0;
  }

  .pt-xl-1 {
    padding-top: 4px;
  }

  .pt-xl-2 {
    padding-top: 8px;
  }

  .pt-xl-3 {
    padding-top: 16px;
  }

  .pt-xl-4 {
    padding-top: 24px;
  }

  .pt-xl-5 {
    padding-top: 32px;
  }

  .pt-xl-6 {
    padding-top: 48px;
  }

  .pt-xl-7 {
    padding-top: 74px;
  }

  .pe-xl-0 {
    padding-right: 0;
  }

  .pe-xl-1 {
    padding-right: 4px;
  }

  .pe-xl-2 {
    padding-right: 8px;
  }

  .pe-xl-3 {
    padding-right: 16px;
  }

  .pe-xl-4 {
    padding-right: 24px;
  }

  .pe-xl-5 {
    padding-right: 32px;
  }

  .pe-xl-6 {
    padding-right: 48px;
  }

  .pe-xl-7 {
    padding-right: 74px;
  }

  .pb-xl-0 {
    padding-bottom: 0;
  }

  .pb-xl-1 {
    padding-bottom: 4px;
  }

  .pb-xl-2 {
    padding-bottom: 8px;
  }

  .pb-xl-3 {
    padding-bottom: 16px;
  }

  .pb-xl-4 {
    padding-bottom: 24px;
  }

  .pb-xl-5 {
    padding-bottom: 32px;
  }

  .pb-xl-6 {
    padding-bottom: 48px;
  }

  .pb-xl-7 {
    padding-bottom: 74px;
  }

  .ps-xl-0 {
    padding-left: 0;
  }

  .ps-xl-1 {
    padding-left: 4px;
  }

  .ps-xl-2 {
    padding-left: 8px;
  }

  .ps-xl-3 {
    padding-left: 16px;
  }

  .ps-xl-4 {
    padding-left: 24px;
  }

  .ps-xl-5 {
    padding-left: 32px;
  }

  .ps-xl-6 {
    padding-left: 48px;
  }

  .ps-xl-7 {
    padding-left: 74px;
  }

  .gap-xl-0 {
    gap: 0;
  }

  .gap-xl-1 {
    gap: 4px;
  }

  .gap-xl-2 {
    gap: 8px;
  }

  .gap-xl-3 {
    gap: 16px;
  }

  .gap-xl-4 {
    gap: 24px;
  }

  .gap-xl-5 {
    gap: 32px;
  }

  .gap-xl-6 {
    gap: 48px;
  }

  .gap-xl-7 {
    gap: 74px;
  }

  .row-gap-xl-0 {
    row-gap: 0;
  }

  .row-gap-xl-1 {
    row-gap: 4px;
  }

  .row-gap-xl-2 {
    row-gap: 8px;
  }

  .row-gap-xl-3 {
    row-gap: 16px;
  }

  .row-gap-xl-4 {
    row-gap: 24px;
  }

  .row-gap-xl-5 {
    row-gap: 32px;
  }

  .row-gap-xl-6 {
    row-gap: 48px;
  }

  .row-gap-xl-7 {
    row-gap: 74px;
  }

  .column-gap-xl-0 {
    column-gap: 0;
  }

  .column-gap-xl-1 {
    column-gap: 4px;
  }

  .column-gap-xl-2 {
    column-gap: 8px;
  }

  .column-gap-xl-3 {
    column-gap: 16px;
  }

  .column-gap-xl-4 {
    column-gap: 24px;
  }

  .column-gap-xl-5 {
    column-gap: 32px;
  }

  .column-gap-xl-6 {
    column-gap: 48px;
  }

  .column-gap-xl-7 {
    column-gap: 74px;
  }

  .fs-xl-1 {
    font-size: 64px;
  }

  .fs-xl-2 {
    font-size: 48px;
  }

  .fs-xl-3 {
    font-size: 42px;
  }

  .fs-xl-4 {
    font-size: 36px;
  }

  .fs-xl-5 {
    font-size: 24px;
  }

  .fs-xl-6 {
    font-size: 18px;
  }

  .fs-xl-7 {
    font-size: 14px;
  }

  .fw-xl-lighter {
    font-weight: lighter;
  }

  .fw-xl-light {
    font-weight: 300;
  }

  .fw-xl-normal {
    font-weight: 400;
  }

  .fw-xl-medium {
    font-weight: 500;
  }

  .fw-xl-semibold {
    font-weight: 600;
  }

  .fw-xl-bold {
    font-weight: 700;
  }

  .fw-xl-extra-bold {
    font-weight: 800;
  }

  .fw-xl-bolder {
    font-weight: 900;
  }

  .lh-xl-1 {
    line-height: 1;
  }

  .lh-xl-sm {
    line-height: 1.3333;
  }

  .lh-xl-base {
    line-height: 1.25;
  }

  .lh-xl-lg {
    line-height: 1.4;
  }

  .text-xl-start {
    text-align: left;
  }

  .text-xl-end {
    text-align: right;
  }

  .text-xl-center {
    text-align: center;
  }

  .rounded-xl {
    border-radius: var(--d-border-radius);
  }

  .rounded-xl-0 {
    border-radius: 0;
  }

  .rounded-xl-1 {
    border-radius: var(--d-border-radius-sm);
  }

  .rounded-xl-2 {
    border-radius: var(--d-border-radius);
  }

  .rounded-xl-3 {
    border-radius: var(--d-border-radius-lg);
  }

  .rounded-xl-4 {
    border-radius: var(--d-border-radius-xl);
  }

  .rounded-xl-5 {
    border-radius: var(--d-border-radius-xxl);
  }

  .rounded-xl-6 {
    border-radius: var(--d-border-radius-3xl);
  }

  .rounded-xl-7 {
    border-radius: var(--d-border-radius-4xl);
  }

  .rounded-xl-circle {
    border-radius: 50%;
  }

  .rounded-xl-pill {
    border-radius: var(--d-border-radius-pill);
  }
}
@media (min-width: 1320px) {
  .d-xxl-inline {
    display: inline;
  }

  .d-xxl-inline-block {
    display: inline-block;
  }

  .d-xxl-block {
    display: block;
  }

  .d-xxl-grid {
    display: grid;
  }

  .d-xxl-inline-grid {
    display: inline-grid;
  }

  .d-xxl-table {
    display: table;
  }

  .d-xxl-table-row {
    display: table-row;
  }

  .d-xxl-table-cell {
    display: table-cell;
  }

  .d-xxl-flex {
    display: flex;
  }

  .d-xxl-inline-flex {
    display: inline-flex;
  }

  .d-xxl-none {
    display: none;
  }

  .position-xxl-static {
    position: static;
  }

  .position-xxl-relative {
    position: relative;
  }

  .position-xxl-absolute {
    position: absolute;
  }

  .position-xxl-fixed {
    position: fixed;
  }

  .position-xxl-sticky {
    position: sticky;
  }

  .w-xxl-72px {
    width: 72px;
  }

  .w-xxl-140px {
    width: 140px;
  }

  .w-xxl-180px {
    width: 180px;
  }

  .w-xxl-25 {
    width: 25%;
  }

  .w-xxl-50 {
    width: 50%;
  }

  .w-xxl-75 {
    width: 75%;
  }

  .w-xxl-100 {
    width: 100%;
  }

  .w-xxl-auto {
    width: auto;
  }

  .mw-xxl-240px {
    max-width: 240px;
  }

  .mw-xxl-250px {
    max-width: 250px;
  }

  .mw-xxl-280px {
    max-width: 280px;
  }

  .mw-xxl-300px {
    max-width: 300px;
  }

  .mw-xxl-400px {
    max-width: 400px;
  }

  .mw-xxl-450px {
    max-width: 450px;
  }

  .mw-xxl-650px {
    max-width: 650px;
  }

  .mw-xxl-70 {
    max-width: 70%;
  }

  .mw-xxl-100 {
    max-width: 100%;
  }

  .h-xxl-60px {
    height: 60px;
  }

  .h-xxl-72px {
    height: 72px;
  }

  .h-xxl-80px {
    height: 80px;
  }

  .h-xxl-140px {
    height: 140px;
  }

  .h-xxl-180px {
    height: 180px;
  }

  .h-xxl-25 {
    height: 25%;
  }

  .h-xxl-50 {
    height: 50%;
  }

  .h-xxl-75 {
    height: 75%;
  }

  .h-xxl-100 {
    height: 100%;
  }

  .h-xxl-auto {
    height: auto;
  }

  .flex-xxl-fill {
    flex: 1 1 auto;
  }

  .flex-xxl-equal {
    flex: 1;
  }

  .flex-xxl-row {
    flex-direction: row;
  }

  .flex-xxl-column {
    flex-direction: column;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-xxl-start {
    justify-content: flex-start;
  }

  .justify-content-xxl-end {
    justify-content: flex-end;
  }

  .justify-content-xxl-center {
    justify-content: center;
  }

  .justify-content-xxl-between {
    justify-content: space-between;
  }

  .justify-content-xxl-around {
    justify-content: space-around;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }

  .align-items-xxl-start {
    align-items: flex-start;
  }

  .align-items-xxl-end {
    align-items: flex-end;
  }

  .align-items-xxl-center {
    align-items: center;
  }

  .align-items-xxl-baseline {
    align-items: baseline;
  }

  .align-items-xxl-stretch {
    align-items: stretch;
  }

  .align-self-xxl-auto {
    align-self: auto;
  }

  .align-self-xxl-start {
    align-self: flex-start;
  }

  .align-self-xxl-end {
    align-self: flex-end;
  }

  .align-self-xxl-center {
    align-self: center;
  }

  .align-self-xxl-baseline {
    align-self: baseline;
  }

  .align-self-xxl-stretch {
    align-self: stretch;
  }

  .m-xxl-0 {
    margin: 0;
  }

  .m-xxl-1 {
    margin: 4px;
  }

  .m-xxl-2 {
    margin: 8px;
  }

  .m-xxl-3 {
    margin: 16px;
  }

  .m-xxl-4 {
    margin: 24px;
  }

  .m-xxl-5 {
    margin: 32px;
  }

  .m-xxl-6 {
    margin: 48px;
  }

  .m-xxl-7 {
    margin: 74px;
  }

  .m-xxl-auto {
    margin: auto;
  }

  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-xxl-1 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .mx-xxl-2 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .mx-xxl-3 {
    margin-right: 16px;
    margin-left: 16px;
  }

  .mx-xxl-4 {
    margin-right: 24px;
    margin-left: 24px;
  }

  .mx-xxl-5 {
    margin-right: 32px;
    margin-left: 32px;
  }

  .mx-xxl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }

  .mx-xxl-7 {
    margin-right: 74px;
    margin-left: 74px;
  }

  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xxl-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .my-xxl-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .my-xxl-3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .my-xxl-4 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .my-xxl-5 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .my-xxl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .my-xxl-7 {
    margin-top: 74px;
    margin-bottom: 74px;
  }

  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xxl-0 {
    margin-top: 0;
  }

  .mt-xxl-1 {
    margin-top: 4px;
  }

  .mt-xxl-2 {
    margin-top: 8px;
  }

  .mt-xxl-3 {
    margin-top: 16px;
  }

  .mt-xxl-4 {
    margin-top: 24px;
  }

  .mt-xxl-5 {
    margin-top: 32px;
  }

  .mt-xxl-6 {
    margin-top: 48px;
  }

  .mt-xxl-7 {
    margin-top: 74px;
  }

  .mt-xxl-auto {
    margin-top: auto;
  }

  .mt-xxl-40px {
    margin-top: 40px;
  }

  .mt-xxl-60px {
    margin-top: 60px;
  }

  .mt-xxl-80px {
    margin-top: 80px;
  }

  .mt-xxl-n7 {
    margin-top: -74px;
  }

  .me-xxl-0 {
    margin-right: 0;
  }

  .me-xxl-1 {
    margin-right: 4px;
  }

  .me-xxl-2 {
    margin-right: 8px;
  }

  .me-xxl-3 {
    margin-right: 16px;
  }

  .me-xxl-4 {
    margin-right: 24px;
  }

  .me-xxl-5 {
    margin-right: 32px;
  }

  .me-xxl-6 {
    margin-right: 48px;
  }

  .me-xxl-7 {
    margin-right: 74px;
  }

  .me-xxl-auto {
    margin-right: auto;
  }

  .mb-xxl-0 {
    margin-bottom: 0;
  }

  .mb-xxl-1 {
    margin-bottom: 4px;
  }

  .mb-xxl-2 {
    margin-bottom: 8px;
  }

  .mb-xxl-3 {
    margin-bottom: 16px;
  }

  .mb-xxl-4 {
    margin-bottom: 24px;
  }

  .mb-xxl-5 {
    margin-bottom: 32px;
  }

  .mb-xxl-6 {
    margin-bottom: 48px;
  }

  .mb-xxl-7 {
    margin-bottom: 74px;
  }

  .mb-xxl-auto {
    margin-bottom: auto;
  }

  .mb-xxl-40px {
    margin-bottom: 40px;
  }

  .mb-xxl-80px {
    margin-bottom: 80px;
  }

  .mb-xxl-n4 {
    margin-bottom: -24px;
  }

  .mb-xxl-n6 {
    margin-bottom: -48px;
  }

  .mb-xxl-n7 {
    margin-bottom: -74px;
  }

  .mb-xxl-n60px {
    margin-bottom: -60px;
  }

  .ms-xxl-0 {
    margin-left: 0;
  }

  .ms-xxl-1 {
    margin-left: 4px;
  }

  .ms-xxl-2 {
    margin-left: 8px;
  }

  .ms-xxl-3 {
    margin-left: 16px;
  }

  .ms-xxl-4 {
    margin-left: 24px;
  }

  .ms-xxl-5 {
    margin-left: 32px;
  }

  .ms-xxl-6 {
    margin-left: 48px;
  }

  .ms-xxl-7 {
    margin-left: 74px;
  }

  .ms-xxl-auto {
    margin-left: auto;
  }

  .p-xxl-0 {
    padding: 0;
  }

  .p-xxl-1 {
    padding: 4px;
  }

  .p-xxl-2 {
    padding: 8px;
  }

  .p-xxl-3 {
    padding: 16px;
  }

  .p-xxl-4 {
    padding: 24px;
  }

  .p-xxl-5 {
    padding: 32px;
  }

  .p-xxl-6 {
    padding: 48px;
  }

  .p-xxl-7 {
    padding: 74px;
  }

  .p-xxl-1px {
    padding: 1px;
  }

  .p-xxl-2px {
    padding: 2px;
  }

  .p-xxl-20px {
    padding: 20px;
  }

  .p-xxl-40px {
    padding: 40px;
  }

  .p-xxl-60px {
    padding: 60px;
  }

  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-xxl-1 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .px-xxl-2 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .px-xxl-3 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .px-xxl-4 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .px-xxl-5 {
    padding-right: 32px;
    padding-left: 32px;
  }

  .px-xxl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }

  .px-xxl-7 {
    padding-right: 74px;
    padding-left: 74px;
  }

  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xxl-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .py-xxl-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .py-xxl-3 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .py-xxl-4 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .py-xxl-5 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .py-xxl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .py-xxl-7 {
    padding-top: 74px;
    padding-bottom: 74px;
  }

  .pt-xxl-0 {
    padding-top: 0;
  }

  .pt-xxl-1 {
    padding-top: 4px;
  }

  .pt-xxl-2 {
    padding-top: 8px;
  }

  .pt-xxl-3 {
    padding-top: 16px;
  }

  .pt-xxl-4 {
    padding-top: 24px;
  }

  .pt-xxl-5 {
    padding-top: 32px;
  }

  .pt-xxl-6 {
    padding-top: 48px;
  }

  .pt-xxl-7 {
    padding-top: 74px;
  }

  .pe-xxl-0 {
    padding-right: 0;
  }

  .pe-xxl-1 {
    padding-right: 4px;
  }

  .pe-xxl-2 {
    padding-right: 8px;
  }

  .pe-xxl-3 {
    padding-right: 16px;
  }

  .pe-xxl-4 {
    padding-right: 24px;
  }

  .pe-xxl-5 {
    padding-right: 32px;
  }

  .pe-xxl-6 {
    padding-right: 48px;
  }

  .pe-xxl-7 {
    padding-right: 74px;
  }

  .pb-xxl-0 {
    padding-bottom: 0;
  }

  .pb-xxl-1 {
    padding-bottom: 4px;
  }

  .pb-xxl-2 {
    padding-bottom: 8px;
  }

  .pb-xxl-3 {
    padding-bottom: 16px;
  }

  .pb-xxl-4 {
    padding-bottom: 24px;
  }

  .pb-xxl-5 {
    padding-bottom: 32px;
  }

  .pb-xxl-6 {
    padding-bottom: 48px;
  }

  .pb-xxl-7 {
    padding-bottom: 74px;
  }

  .ps-xxl-0 {
    padding-left: 0;
  }

  .ps-xxl-1 {
    padding-left: 4px;
  }

  .ps-xxl-2 {
    padding-left: 8px;
  }

  .ps-xxl-3 {
    padding-left: 16px;
  }

  .ps-xxl-4 {
    padding-left: 24px;
  }

  .ps-xxl-5 {
    padding-left: 32px;
  }

  .ps-xxl-6 {
    padding-left: 48px;
  }

  .ps-xxl-7 {
    padding-left: 74px;
  }

  .gap-xxl-0 {
    gap: 0;
  }

  .gap-xxl-1 {
    gap: 4px;
  }

  .gap-xxl-2 {
    gap: 8px;
  }

  .gap-xxl-3 {
    gap: 16px;
  }

  .gap-xxl-4 {
    gap: 24px;
  }

  .gap-xxl-5 {
    gap: 32px;
  }

  .gap-xxl-6 {
    gap: 48px;
  }

  .gap-xxl-7 {
    gap: 74px;
  }

  .row-gap-xxl-0 {
    row-gap: 0;
  }

  .row-gap-xxl-1 {
    row-gap: 4px;
  }

  .row-gap-xxl-2 {
    row-gap: 8px;
  }

  .row-gap-xxl-3 {
    row-gap: 16px;
  }

  .row-gap-xxl-4 {
    row-gap: 24px;
  }

  .row-gap-xxl-5 {
    row-gap: 32px;
  }

  .row-gap-xxl-6 {
    row-gap: 48px;
  }

  .row-gap-xxl-7 {
    row-gap: 74px;
  }

  .column-gap-xxl-0 {
    column-gap: 0;
  }

  .column-gap-xxl-1 {
    column-gap: 4px;
  }

  .column-gap-xxl-2 {
    column-gap: 8px;
  }

  .column-gap-xxl-3 {
    column-gap: 16px;
  }

  .column-gap-xxl-4 {
    column-gap: 24px;
  }

  .column-gap-xxl-5 {
    column-gap: 32px;
  }

  .column-gap-xxl-6 {
    column-gap: 48px;
  }

  .column-gap-xxl-7 {
    column-gap: 74px;
  }

  .fs-xxl-1 {
    font-size: 64px;
  }

  .fs-xxl-2 {
    font-size: 48px;
  }

  .fs-xxl-3 {
    font-size: 42px;
  }

  .fs-xxl-4 {
    font-size: 36px;
  }

  .fs-xxl-5 {
    font-size: 24px;
  }

  .fs-xxl-6 {
    font-size: 18px;
  }

  .fs-xxl-7 {
    font-size: 14px;
  }

  .fw-xxl-lighter {
    font-weight: lighter;
  }

  .fw-xxl-light {
    font-weight: 300;
  }

  .fw-xxl-normal {
    font-weight: 400;
  }

  .fw-xxl-medium {
    font-weight: 500;
  }

  .fw-xxl-semibold {
    font-weight: 600;
  }

  .fw-xxl-bold {
    font-weight: 700;
  }

  .fw-xxl-extra-bold {
    font-weight: 800;
  }

  .fw-xxl-bolder {
    font-weight: 900;
  }

  .lh-xxl-1 {
    line-height: 1;
  }

  .lh-xxl-sm {
    line-height: 1.3333;
  }

  .lh-xxl-base {
    line-height: 1.25;
  }

  .lh-xxl-lg {
    line-height: 1.4;
  }

  .text-xxl-start {
    text-align: left;
  }

  .text-xxl-end {
    text-align: right;
  }

  .text-xxl-center {
    text-align: center;
  }

  .rounded-xxl {
    border-radius: var(--d-border-radius);
  }

  .rounded-xxl-0 {
    border-radius: 0;
  }

  .rounded-xxl-1 {
    border-radius: var(--d-border-radius-sm);
  }

  .rounded-xxl-2 {
    border-radius: var(--d-border-radius);
  }

  .rounded-xxl-3 {
    border-radius: var(--d-border-radius-lg);
  }

  .rounded-xxl-4 {
    border-radius: var(--d-border-radius-xl);
  }

  .rounded-xxl-5 {
    border-radius: var(--d-border-radius-xxl);
  }

  .rounded-xxl-6 {
    border-radius: var(--d-border-radius-3xl);
  }

  .rounded-xxl-7 {
    border-radius: var(--d-border-radius-4xl);
  }

  .rounded-xxl-circle {
    border-radius: 50%;
  }

  .rounded-xxl-pill {
    border-radius: var(--d-border-radius-pill);
  }
}
.header .btn,
.footer .btn {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: inherit;
  letter-spacing: 0.02em;
  position: relative;
  cursor: pointer;
  /*@include xs {
    -webkit-appearance: button;
  }*/
}
.header .btn--sm,
.footer .btn--sm {
  font-size: 14px;
  font-weight: 600;
}
.header .btn--md,
.footer .btn--md {
  font-size: 18px;
  font-weight: 400;
}
.header .btn--default,
.footer .btn--default {
  background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), #4996D1;
  border-radius: 67px;
  color: #fff;
  padding: 9px 25px;
}
.header .btn--defaultDark,
.footer .btn--defaultDark {
  background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
}
.header .btn--default:hover, .header .btn--default:focus,
.footer .btn--default:hover,
.footer .btn--default:focus {
  text-decoration: none;
  background: #CE72CB;
}
.header .btn--xs,
.footer .btn--xs {
  padding: 8px 19px;
  line-height: 1.4 !important;
}
.header .btn--xs.btn--arrow:after,
.footer .btn--xs.btn--arrow:after {
  margin-left: 5px;
}
.header .btn--arrow,
.footer .btn--arrow {
  display: inline-flex;
  align-items: center;
}
.header .btn--arrow::after,
.footer .btn--arrow::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 13px;
  background-image: url("/assets/website/img/icons/arrow.svg");
  margin-left: 12px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.header .btn--arrowGrad:after,
.footer .btn--arrowGrad:after {
  background-image: url("/assets/website/img/icons/arrow-gradient.svg");
  transition: all 0.3s;
}

@media (max-width: 1024px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 150;
    transition: background-color 0.2s;
  }
}
.header .menu {
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .header .menu {
    display: block;
    overflow: hidden;
    padding: 30px 0;
  }
}
@media (max-width: 1024px) {
  .header .menu > ul {
    overflow: auto;
    height: 100%;
    position: relative;
    margin: 0 auto;
  }
  .header .menu > ul::-webkit-scrollbar {
    width: 4px;
  }
  .header .menu > ul::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .header .menu > ul::-webkit-scrollbar-thumb {
    background: #CE72CB;
  }
  .header .menu > ul::-webkit-scrollbar-thumb:hover {
    background: #c14cbd;
  }
}
@media (max-width: 1024px) {
  .header .menu > ul li {
    height: auto;
  }
}
@media (max-width: 1024px) {
  .header .burger,
.header .logo {
    filter: brightness(10);
    transition: filter 0.2s;
  }
}
@media (max-width: 1024px) {
  .header--fixed {
    background-color: #fff;
  }
}
@media (max-width: 1024px) {
  .header--fixed .burger,
.header--fixed .logo {
    filter: brightness(1);
    transition: filter 0.2s;
  }
}
.header--white {
  background-color: #fff;
}
@media (max-width: 1024px) {
  .header--white .burger,
.header--white .logo {
    filter: brightness(1);
  }
}
.header__inner {
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .header__inner {
    padding-top: 30px;
    padding-bottom: 19px;
  }
}
.header__action {
  margin-right: auto;
  white-space: nowrap;
}
@media (max-width: 1024px) {
  .header__action {
    display: none;
  }
}
.header.scrolled {
  background: #fff;
}
.header_docs {
  display: flex;
  align-items: center;
  box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -16px -16px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;
}
@media (max-width: 1024px) {
  .header_docs {
    height: 67px;
    box-shadow: none;
  }
}
.header_docs .header__inner {
  display: flex;
  justify-content: space-between;
  padding: 42px 0 36px 8px;
}
@media (max-width: 1024px) {
  .header_docs .header__inner {
    padding: 20px 0 29px;
  }
}
@media (max-width: 1024px) {
  .header_docs .container {
    max-width: 87%;
  }
}
.header_docs .menu {
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
}
@media (max-width: 1024px) {
  .header_docs .menu {
    width: 100%;
    height: 100%;
  }
}
.header_docs .logo {
  display: flex;
  align-items: flex-end;
}
@media (max-width: 1024px) {
  .header_docs .logo {
    display: none;
  }
}
.header_docs .logo img {
  width: 178px;
}
.header_docs .burger {
  margin-left: 0;
}
.header_docs--integration .container {
  width: 98%;
}
@media (max-width: 1024px) {
  .header_docs--integration .container {
    padding: 29px 15px 27px;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .header_docs--integration .logo {
    display: block;
  }
}
@media (max-width: 1024px) {
  .header_docs--integration .logo img {
    width: auto;
  }
}

.search_btn {
  border: none;
  background: none;
  cursor: pointer;
}

.burger {
  display: none;
  margin-left: auto;
}
@media (max-width: 1024px) {
  .burger {
    display: block;
  }
}

@media (max-width: 1024px) {
  .lang {
    display: none;
  }
}

.close, .header .close {
  padding: 5px;
  position: absolute;
  top: 50px;
  right: calc(9% - 5px);
  display: none;
}
@media (max-width: 1024px) {
  .close, .header .close {
    display: block;
  }
}

.logo img {
  width: 133px;
  height: 28px;
}
@media (max-width: 1024px) {
  .logo img {
    width: 98px;
    height: 19px;
  }
}

.user {
  margin-left: 18px;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .user {
    display: none;
  }
}

.menuChild {
  padding-top: 20px;
  position: absolute;
  top: 100%;
  left: -53px;
  z-index: 50;
  width: max-content;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s;
}
@media (max-width: 1223px) {
  .menuChild {
    padding-top: 10px;
    left: -24px;
  }
}
@media (max-width: 1024px) {
  .menuChild {
    padding-top: 0;
    position: static;
    width: 100%;
    margin-top: 15px;
  }
}
.menuChild--products .menuChild__wrapper {
  background: left 0 top 0/auto 100% no-repeat url("/assets/website/img/header/child-menu-products.jpg") #fff;
}
@media (max-width: 1024px) {
  .menuChild--products .menuChild__content {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 768px) {
  .menuChild--products .menuChild__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
.menuChild--solutions .menuChild__wrapper {
  background: left 0 top 0/auto 100% no-repeat url("/assets/website/img/header/child-menu-solutions.jpg") #fff;
}
.menuChild--solutions .menuChild__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}
@media (max-width: 1024px) {
  .menuChild--solutions .menuChild__content {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px 24px;
  }
}
@media (max-width: 768px) {
  .menuChild--solutions .menuChild__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
.menuChild--solutions .menuChild__content li:not(:last-child) {
  margin-bottom: 0;
}
.menuChild--company .menuChild__wrapper {
  background: left 0 top 0/auto 100% no-repeat url("/assets/website/img/header/child-menu-company.jpg") #fff;
}
.menuChild--contacts .menuChild__wrapper {
  background: left 0 top 0/auto 100% no-repeat url("/assets/website/img/header/child-menu-contacts.jpg") #fff;
}
.menuChild .menuChild__wrapper {
  display: flex;
  padding: 44px 53px;
  box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
  border-radius: 24px;
}
@media (max-width: 1223px) {
  .menuChild .menuChild__wrapper {
    padding: 24px;
  }
}
@media (max-width: 1024px) {
  .menuChild .menuChild__wrapper {
    flex-direction: column;
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    padding: 0;
  }
}
.menuChild__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .menuChild__main {
    max-width: none;
  }
}
@media (max-width: 1024px) {
  .menuChild__header {
    display: none;
  }
}
.menuChild__footer {
  max-width: 240px;
  margin-top: auto;
}
@media (max-width: 1024px) {
  .menuChild__footer {
    /*display: flex;
    flex-wrap: wrap;
    align-items: center;*/
    max-width: none;
    margin-bottom: 6px;
  }
}
.menuChild__footer .menuChild__subtitle {
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .menuChild__footer .menuChild__subtitle {
    /*display: block;
    margin-top: 0;
    margin-bottom: 19px;
    margin-right: 20px;
    color: $color_primary;
    background: $gradient_primary;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;*/
  }
}
.menuChild__footer .menuChild__btn {
  margin-top: 13px;
}
@media (max-width: 1024px) {
  .menuChild__footer .menuChild__btn {
    margin-top: 0;
    margin-bottom: 19px;
  }
}
.menuChild__footer .menuChild__btn .btn {
  font-size: 14px;
}
.menuChild__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 14px;
  letter-spacing: 0.05em;
  color: #4996D1;
  background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), #4996D1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 1223px) {
  .menuChild__title {
    font-size: 20px;
  }
}
.menuChild__subtitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #5C6876;
  letter-spacing: 0.05em;
}
@media (max-width: 1223px) {
  .menuChild__subtitle {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 1024px) {
  .menuChild__subtitle {
    display: none;
  }
}
.menuChild__content {
  margin-left: 92px;
  margin-top: 3px;
}
@media (max-width: 1223px) {
  .menuChild__content {
    margin-left: 24px;
  }
}
@media (max-width: 1024px) {
  .menuChild__content {
    margin-left: 0;
    margin-top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px 24px;
  }
}
.menuChild__content li:not(:last-child) {
  margin-bottom: 24px;
}
@media (max-width: 1223px) {
  .menuChild__content li:not(:last-child) {
    margin-bottom: 16px;
  }
}
@media (max-width: 1024px) {
  .menuChild__content li:not(:last-child) {
    margin-bottom: 0;
  }
}
.menuChild__content li a {
  color: #4996D1;
  background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), #4996D1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.02em !important;
}
@media (max-width: 1024px) {
  .menuChild__content li a {
    letter-spacing: 0.05em !important;
    line-height: 20px !important;
  }
}
.menuChild__content li a:hover {
  -webkit-text-fill-color: #CE72CB;
}
.menuChild__content--two {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 24px 40px;
}
@media (max-width: 1223px) {
  .menuChild__content--two {
    grid-gap: 16px 24px;
  }
}
@media (max-width: 1024px) {
  .menuChild__content--two {
    grid-template-columns: repeat(2, 1fr);
  }
}
.menuChild__content--two li {
  margin-bottom: 0 !important;
}

.menu {
  margin-left: auto;
  align-self: stretch;
  transition: all 0.2s;
}
@media (max-width: 1440px) {
  .menu {
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    background-color: rgba(255, 255, 255, 0.9);
  }
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  @media (max-width: 1024px) {
    .menu {
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(150px);
      -webkit-backdrop-filter: blur(150px);
    }
  }
}
.menu.active {
  opacity: 1;
  pointer-events: auto;
  transform: none;
}
@media (max-width: 1024px) {
  .menu.active .menuChild {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu > ul {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .menu > ul {
    display: block;
    height: auto;
    border-radius: 18px;
    background: #fff;
    margin: 30px auto;
    width: 91%;
    box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
  }
}
.menu > ul > li {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 43px 78px 43px 0;
}
@media (max-width: 1366px) {
  .menu > ul > li {
    padding: 30px 50px 30px 0;
  }
}
@media (max-width: 1223px) {
  .menu > ul > li {
    padding: 30px 30px 30px 0;
  }
}
@media (max-width: 1024px) {
  .menu > ul > li {
    display: block;
    padding: 24px 28px;
  }
}
.menu > ul > li.menu__footer {
  display: none;
}
@media (max-width: 1024px) {
  .menu > ul > li.menu__footer {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 1024px) {
  .menu > ul > li.menu__footer .user,
.menu > ul > li.menu__footer .lang,
.menu > ul > li.menu__footer .header__action {
    display: block;
  }
}
.menu > ul > li.menu__footer .header__action {
  margin-right: 0;
  margin-left: auto;
}
.menu > ul > li.menu__footer .user a {
  display: flex;
  align-items: center;
  color: #959BAF;
}
.menu > ul > li.menu__footer .user img {
  margin-right: 13px;
}
.menu > ul > li.menu__footer .dropdown__content {
  top: -13px;
}
.menu > ul > li.menu__footer .dropdown__title {
  font-size: 14px;
}
.menu > ul > li::before {
  content: "";
  position: absolute;
  width: calc(100% - 78px);
  height: 3px;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.2s;
}
@media (max-width: 1366px) {
  .menu > ul > li::before {
    width: calc(100% - 50px);
  }
}
@media (max-width: 1223px) {
  .menu > ul > li::before {
    width: calc(100% - 30px);
  }
}
@media (max-width: 1024px) {
  .menu > ul > li::before {
    width: 100%;
    background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
    height: 1px;
    top: 100%;
  }
}
@media (max-width: 1024px) {
  .menu > ul > li:nth-last-child(-n+1):before {
    content: none;
  }
}
.menu > ul > li > span,
.menu > ul > li a {
  display: inline-block;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.05em;
  transition: all 0.2s;
}
@media (max-width: 1223px) {
  .menu > ul > li > span,
.menu > ul > li a {
    font-size: 14px;
  }
}
.menu > ul > li > span svg,
.menu > ul > li a svg {
  margin-left: 8px;
}
@media (max-width: 1024px) {
  .menu > ul > li > span svg,
.menu > ul > li a svg {
    display: none;
  }
}
.menu > ul > li > span svg path,
.menu > ul > li a svg path {
  fill: #5C6876;
  transition: all 0.2s;
}
.menu > ul > li > span {
  color: #5C6876;
}
@media (max-width: 1024px) {
  .menu > ul > li > span {
    text-transform: uppercase;
  }
}
.menu > ul > li:hover::before {
  background: #CE72CB;
}
@media (max-width: 1024px) {
  .menu > ul > li:hover::before {
    background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
  }
}
.menu > ul > li:hover > span {
  color: #CE72CB;
}
@media (max-width: 1024px) {
  .menu > ul > li:hover > span {
    color: #5C6876;
  }
}
.menu > ul > li:hover > span svg path {
  fill: #CE72CB;
}
.menu > ul > li:hover .menuChild {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.1s;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  cursor: default;
}

.footer {
  padding-top: 55px;
  padding-bottom: 47px;
  background: center/cover no-repeat url("/assets/website/img/bg-footer.jpg") #2a40a5;
}
@media (max-width: 1366px) {
  .footer {
    padding-top: 60px;
    padding-bottom: 72px;
    background: center/cover no-repeat url("/assets/website/img/bg-footer-tablet.jpg") #2a40a5;
  }
}
@media (max-width: 768px) {
  .footer {
    padding-top: 27px;
    padding-bottom: 45px;
    background: center/cover no-repeat url("/assets/website/img/bg-footer-mobile.jpg") #2a40a5;
  }
}
.footer__title {
  color: #93DEEF;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.02em;
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .footer .btn--default {
    background: #CE72CB;
  }
}
.footer__info p {
  font-size: 8px;
  letter-spacing: 0.02em;
  line-height: 11px;
  color: #F1F4F6;
}
.footer__info p:first-of-type {
  text-align: justify;
  margin-bottom: 8px;
}
@media (max-width: 1366px) {
  .footer__info p:first-of-type {
    margin-bottom: 5px;
  }
}
.footer__menu li:not(:last-child) {
  margin-bottom: 18px;
}
.footer__menu li a {
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02em;
}
.footer__menu li a:hover {
  color: #EA9BE7;
}
.footer__inner {
  position: relative;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 15px;
  grid-row-gap: 8px;
  color: #ffffff;
}
@media (max-width: 1366px) {
  .footer__inner {
    grid-template-columns: repeat(4, auto);
  }
}
@media (max-width: 768px) {
  .footer__inner {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 17px;
    grid-row-gap: 30px;
  }
}
.footer__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.footer__list--main {
  position: relative;
  max-width: 225px;
  grid-area: 1/1/3/2;
}
@media (max-width: 1366px) {
  .footer__list--main {
    max-width: 311px;
  }
}
@media (max-width: 768px) {
  .footer__list--main {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    grid-area: 1/1/2/3;
  }
}
.footer__list--main .footer__list--socials {
  display: none;
}
@media (max-width: 1366px) {
  .footer__list--main .footer__list--socials {
    display: block;
    margin-top: 40px;
  }
}
@media (max-width: 768px) {
  .footer__list--main .footer__list--socials {
    display: none;
  }
}
.footer__list--products {
  max-width: 325px;
  grid-area: 1/2/3/3;
}
@media (max-width: 1366px) {
  .footer__list--products {
    max-width: 157px;
    grid-area: 1/2/2/3;
  }
}
@media (max-width: 768px) {
  .footer__list--products {
    grid-area: 2/1/3/2;
  }
}
.footer__list--solutions {
  max-width: 240px;
  grid-area: 1/3/3/4;
}
@media (max-width: 1366px) {
  .footer__list--solutions {
    max-width: 148px;
    grid-area: 1/4/3/5;
  }
}
@media (max-width: 768px) {
  .footer__list--solutions {
    grid-area: 3/1/6/2;
  }
}
.footer__list--company {
  max-width: 169px;
  grid-area: 1/4/2/5;
}
@media (max-width: 1366px) {
  .footer__list--company {
    max-width: 157px;
    grid-area: 2/3/3/4;
  }
}
@media (max-width: 768px) {
  .footer__list--company {
    grid-area: 4/2/5/3;
  }
}
.footer__list--contact {
  max-width: 130px;
  grid-area: 1/5/2/6;
}
@media (max-width: 1366px) {
  .footer__list--contact {
    max-width: 157px;
    grid-area: 2/2/3/3;
  }
}
@media (max-width: 768px) {
  .footer__list--contact {
    grid-area: 3/2/4/3;
  }
}
.footer__list--documentation {
  max-width: 169px;
  grid-area: 2/4/3/5;
}
@media (max-width: 1366px) {
  .footer__list--documentation {
    max-width: 157px;
    grid-area: 1/3/2/4;
  }
}
@media (max-width: 768px) {
  .footer__list--documentation {
    grid-area: 2/2/3/3;
  }
}
.footer__list--socials {
  max-width: 130px;
  grid-area: 2/5/3/6;
}
@media (max-width: 1366px) {
  .footer__list--socials {
    display: none;
  }
}
@media (max-width: 768px) {
  .footer__list--socials {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 13px;
    grid-area: 5/2/6/3;
  }
}
.footer__list--socials .footer__menu {
  display: flex;
  align-items: center;
  margin: -5px;
}
.footer__list--socials .footer__menu li a {
  padding: 8px;
}
.footer__list--socials .footer__menu li:not(:last-child) {
  margin-bottom: 0;
}
.footer__creator {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 1366px) {
  .footer__creator {
    bottom: -34px;
    left: -22px;
  }
}
@media (max-width: 768px) {
  .footer__creator {
    bottom: -10px;
    right: 0;
    left: auto;
  }
}
@media (max-width: 1366px) {
  .footer__creator img {
    width: 39px;
  }
}
.footer__cta {
  margin-top: auto;
  max-width: 180px;
}
@media (max-width: 1366px) {
  .footer__cta {
    display: none;
    max-width: 190px;
    margin-top: 35px;
    padding-left: 8px;
  }
}
@media (max-width: 768px) {
  .footer__cta {
    width: 60%;
    margin-top: 23px;
    padding-left: 0;
    padding-right: 10px;
  }
}
.footer__cta--mobile {
  display: none;
}
@media (max-width: 1366px) {
  .footer__cta--mobile {
    display: block;
  }
}
.footer__cta p {
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.05em;
}
@media (max-width: 1366px) {
  .footer__cta p {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .footer__cta p {
    margin-bottom: 12px;
  }
}
.footer__brands {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  min-height: 39px;
}
@media (max-width: 1366px) {
  .footer__brands {
    margin-top: 27px;
  }
}
@media (max-width: 768px) {
  .footer__brands {
    order: 2;
    width: 40%;
    max-width: 100px;
    margin-top: 10px;
  }
}
.footer__brands img {
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer__brands img:not(:last-child) {
  margin-right: 8px;
}
@media (max-width: 768px) {
  .footer__brands img:not(:last-child) {
    margin-right: 0;
  }
}
.footer__lang {
  margin-top: 9px;
}
@media (max-width: 1366px) {
  .footer__lang {
    position: absolute;
    top: 2px;
    right: 45px;
    margin-top: 0;
  }
}
@media (max-width: 1024px) {
  .footer__lang {
    right: 0;
    top: 4px;
  }
}
.footer__lang .dropdown__content {
  background-color: #374D95;
}
.footer__lang .dropdown__content li.active a {
  color: #ffffff;
}
.footer__lang .dropdown__content a {
  color: #959BAF;
}
.footer__lang .dropdown__content a:hover {
  color: #ffffff;
}
.footer__lang .dropdown__content svg path {
  fill: #ffffff;
}

.logo--footer {
  margin-bottom: 18px;
}
@media (max-width: 1366px) {
  .logo--footer {
    margin-bottom: 13px;
  }
}
.logo--footer img {
  width: 130px;
  height: 32px;
}