$__IMG_PATH: '/assets/website/img/';
$__FONTS_PATH: '/assets/website/fonts/';

$prefix: d-;

// - Options (Quickly modify global styling by enabling or disabling optional features.)
$include-column-box-sizing: true;
$enable-important-utilities: false;
$enable-negative-margins: false;
$enable-grid-classes: true;
$enable-cssgrid: false;
$enable-offsets: false;

// - Fonts
$font-family-sans-serif: "Panton", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-family-base: var(--#{$prefix}font-sans-serif);
$font-family-code: var(--#{$prefix}font-monospace);


// - Colors
$black: #000;
$white: #FFF;

$grey: #F3F5F7;
$gray-900: #959BAF;
$primary: #559BEB;
$secondary: #8592F4;
$accent: #94F7FF;
$pink: #CE72CB;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "accent": $accent,
        "pink": $pink
);

$gradient: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%) $primary;
$gradient-code-border: linear-gradient(180deg, #A0FAFC 0.13%, rgba(#A0FAFC, 0) 90.54%), linear-gradient(90deg, #5E6FB1 0%, #584B7B 100%);
$gradient-code-mask: linear-gradient(318deg, rgba(0, 0, 0, 0.5) 20.61%, rgba(0, 0, 0, 0) 88.71%);
$gradient-gray-border: linear-gradient(118.53deg, #FFFFFF 26%, rgba(219, 230, 242, 0) 51%, #8F9FAE 100%);

$body-color: #5C6876;
$body-bg: $white;

// - Computed contrasts
$min-contrast-ratio: 4.5;

$color-contrast-dark: $black;
$color-contrast-light: $white;

// - Font Weights
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-bolder: 900;

$font-weight-base: $font-weight-normal;


// - Line heights
$line-height-base: 1.25;
$line-height-sm: 1.3333;
$line-height-lg: 1.4;


// - Font Sizes
$font-size-base: 16px;
$h1-font-size: $font-size-base * 4; // 64px
$h2-font-size: $font-size-base * 3; // 48px
$h3-font-size: $font-size-base * 2.625; // 42px
$h4-font-size: $font-size-base * 2.25; // 36px
$h5-font-size: $font-size-base * 1.5; // 24px
$h6-font-size: $font-size-base * 1.125; // 18px

$small-font-size: $font-size-base * 0.875; // 14px
$sub-sup-font-size: .75em;

$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size,
        7: $small-font-size
);

// - Shadows
$box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -15px 2px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;
$box-shadow-sm: 0 4px 30px 0 rgba(43, 61, 74, 0.36);
$box-shadow-lg: -8px -10px 30px 0 rgba(232, 234, 243, 0.05), 18px 12px 45px 0 rgba(23, 37, 87, 0.42), -16px -6px 80px 0 rgba(248, 249, 249, 0.03) inset;
$box-shadow-inset: 2px 1px 3px 0 rgba(24, 47, 91, 0.2), -2px -1px 4px 0 rgba(255, 255, 255, 0.4), -4px -3px 4px 0 rgba(79, 103, 120, 0.2) inset, 3px 3px 5px 0 rgba(213, 222, 245, 0.4) inset, 0 0 4px 0 rgba(49, 46, 87, 0) inset;


// - Border Radius's
$border-radius: 16px;
$border-radius-sm: 14px;
$border-radius-lg: 18px;
$border-radius-xl: 20px;
$border-radius-xxl: 24px;
$border-radius-3xl: 40px;
$border-radius-4xl: 68px;
$border-radius-pill: 50rem;


// - Spacers
$spacer: 16px;
$spacers: (
        0: 0,
        1: $spacer * .25, // 4px
        2: $spacer * .5, // 8px
        3: $spacer, // 16px
        4: $spacer * 1.5, // 24px
        5: $spacer * 2, // 32px
        6: $spacer * 3, // 48px
        7: $spacer * 4.625 // 74px
);


// - Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1320px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// - Grid containers
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1302px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");


// - Grid columns (Set the number of columns and specify the width of the gutters.)
$grid-columns: 12;
$grid-gutter-width: 1.5rem;
$grid-row-columns: 6;
$gutters: $spacers;


// - Container padding
$container-padding-x: $spacer * 2;


// - Z indexes
$zindex-levels: (
        n1: -1,
        0: 0,
        1: 1,
        2: 2,
        3: 3
);


// - Ratios
$aspect-ratios: (
        //"1x1": 100%,
        //"3x2": calc(2 / 3 * 100%),
        //"4x3": calc(3 / 4 * 100%),
        //"11x8": calc(8 / 11 * 100%),
        //"14x11": calc(11 / 14 * 100%),
        //"16x9": calc(9 / 16 * 100%),
        //"21x9": calc(9 / 21 * 100%),
);


// - Buttons
$btn-color: var(--#{$prefix}body-color);
$btn-padding-y: 8px;
$btn-padding-x: 20px;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 14px;
$btn-font-weight: $font-weight-semibold;
$btn-line-height: 1.429;

$btn-padding-y-sm: $btn-padding-y;
$btn-padding-x-sm: $btn-padding-x;
$btn-font-size-sm: 14px;
$btn-line-height-sm: 1.429;

$btn-padding-y-lg: $btn-padding-y;
$btn-padding-x-lg: $btn-padding-x;
$btn-font-size-lg: 18px;
$btn-line-height-lg: 1.445;


$btn-border-width: 0;
$btn-border-radius: var(--#{$prefix}border-radius-pill);
$btn-border-radius-sm: var(--#{$prefix}border-radius-pill);
$btn-border-radius-lg: var(--#{$prefix}border-radius-pill);

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
